import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { CommandPaletteService } from './command-palette.service';

@NgModule({
  declarations: [],
  providers: [],
  imports: [CommonModule, SharedModule],
})
export class CommandPaletteModule {
  static forRoot(): ModuleWithProviders<CommandPaletteModule> {
    return {
      ngModule: CommandPaletteModule,
      providers: [{ provide: CommandPaletteService }],
    };
  }
}
