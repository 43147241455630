/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type CreateSummaryMutationVariables = Types.Exact<{
  input: Types.CreateSummaryInput;
}>;


export type CreateSummaryMutation = { __typename?: 'Mutation', payload?: { __typename?: 'CreateSummaryPayload', summary?: { __typename?: 'Summary', id: string } | null } | null };

export const CreateSummaryDocument = gql`
    mutation CreateSummary($input: CreateSummaryInput!) {
  payload: createSummary(input: $input) {
    summary {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSummaryMutationService extends Mutation<CreateSummaryMutation, CreateSummaryMutationVariables> {
    document = CreateSummaryDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }