import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

type LogArgument = unknown;

@Injectable()
export class LoggerService {
  info(...args: LogArgument[]) {
    const [first, ...rest] = args;
    /* eslint-disable-next-line no-console */
    console.info(first, rest);
  }

  log(...args: LogArgument[]) {
    const [first, ...rest] = args;
    /* eslint-disable-next-line no-console */
    console.log(first, rest);
  }

  warning(...args: LogArgument[]) {
    const [first, ...rest] = args;
    console.warn(first, rest);
  }

  error(...args: LogArgument[]) {
    const [first, ...rest] = args;
    console.error(first, rest);

    if (first !== null && typeof first === 'object') {
      if (first instanceof Error) {
        Sentry.captureException(first);
      } else if ('error' in first && first.error instanceof Error) {
        Sentry.captureException(first.error);
      } else if ('message' in first && first.message) {
        Sentry.captureException(first.message);
      }
    }
  }
}
