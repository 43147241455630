import { Pipe, PipeTransform } from '@angular/core';

import { formatDistanceStrict } from '@app/utils';

@Pipe({
  name: 'omgDateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(date: string): any {
    return formatDistanceStrict(date, new Date(), { addSuffix: true });
  }
}
