import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'apply' })
export class ApplyFuncPipe implements PipeTransform {
  constructor() {}

  transform(obj: any, func: Function, ...args): any {
    return func(obj, ...args);
  }
}
