import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import * as Sentry from '@sentry/angular-ivy';
import { mergeMap, Observable, tap } from 'rxjs';

import { ConfigService } from '../../config/config.service';
import { IndexName } from './types/index-name';
import { SearchPayload } from './types/search-payload';

@Injectable()
export class OpenSearchDataFetcher {
  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private httpClient: HttpClient,
  ) {}

  search(index: IndexName, payload: SearchPayload): Observable<any> {
    const base = this.configService.environment.search.proxyHost;
    const url = `${base}/${index}/_search`;
    return this.authService.getAccessTokenSilently().pipe(
      mergeMap(token => this.dispatch(url, payload, token)),
      tap(response => this.reportFailures(response)),
    );
  }

  private dispatch(url: string, body: any, token: string): Observable<any> {
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.httpClient.post(url, body, options);
  }

  private reportFailures(response: any): void {
    // inspect opensearch api response and log if there are any failed shards
    // https://opensearch.org/docs/1.3/api-reference/search/#response-body
    // https://www.elastic.co/guide/en/elasticsearch/reference/current/docs-index_.html#docs-index-api-response-body
    // we don't check for `response._shards.skipped` because the default behaviour is to fail if any shards in a cluster fail.
    // shards are only skipped if the query explicitly sets `skip_unavailable` = true
    const failed = response?._shards?.failed > 0;
    if (failed) {
      Sentry.captureException(
        `OpenSearch response contains failures: ${response?._shards?.failures}`,
      );
    }
  }
}
