import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

import { PatientSelectors, Profile, ProfileSelectors } from '@app/core';
import { PharmacyAddress } from '@app/modules/pharmacy-picker/shared/pharmacy.type';

@Component({
  selector: 'omg-rx-credential-warning',
  templateUrl: './rx-credential-warning.component.html',
})
export class RxCredentialWarningComponent implements OnInit {
  @Input() numberOfValidPrescribers: number;
  @Input() hasControlledSubstances: boolean;
  @Input() pharmacyAddress: PharmacyAddress;

  profile$: Observable<Profile>;
  isProvider$: Observable<boolean>;
  patientIsVirtual$: Observable<boolean>;

  constructor(
    private profileSelectors: ProfileSelectors,
    private patientSelectors: PatientSelectors,
  ) {}

  ngOnInit() {
    this.setupSelectors();
  }

  private setupSelectors(): void {
    this.profile$ = this.profileSelectors.profile;
    this.isProvider$ = this.profileSelectors
      .hasRole('provider')
      .pipe(take(1), shareReplay(1));
    this.patientIsVirtual$ = this.patientSelectors.isVirtual;
  }
}
