<omg-section-title>
  Assessment & Plan
</omg-section-title>
<omg-linked-enhanced-assessment-plan *ngIf="useEnhancedAP; else basicAP"
                                     [signed]="signed"
                                     [assessedProblems]="assessedProblems"
                                     (unlink)="deleteFromNote($event)">
</omg-linked-enhanced-assessment-plan>
<ng-template #basicAP>
  <ul class="padding-normal"
      om-layout="vertical"
      om-layout-gutter>
    <li class="om-animate"
        *ngFor="let problem of assessedProblems; trackBy: trackByFn">
      <div om-layout
           om-layout-align="space-between center">
        <span>
          <strong>{{ problem.problemType.clinicalDescription }}</strong>
          <span *ngIf="problem.problemCodeLocation?.name">, {{problem.problemCodeLocation.name}}</span>
          <span *ngIf="problem.briefComment"> - {{ problem.briefComment }}</span>
          <span *ngIf="problem.onset || problem.resolution"> (
            <span *ngIf="problem.onset">onset {{ problem.onset }}</span>
            <span *ngIf="problem.onset && problem.resolution">, </span>
            <span *ngIf="problem.resolution">resolved {{ problem.resolution }}</span>
            <span>)</span>
          </span>
          <span *ngIf="problem.code"> | {{ problem.code }}</span>
        </span>
        <span class="om-icon clickable fa fa-trash"
              (click)="deleteFromNote(problem)"
              *ngIf="!signed"
              omgStopEvent="click"
              omgTooltip
              tooltipText="Delete from Note"
              tooltipPosition="left"></span>
      </div>
      <p *ngIf="problem.aAndP">{{ problem.aAndP }}</p>
    </li>
  </ul>
</ng-template>
