import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SmartDraftService } from '@app/modules/messaging/shared/smart-draft.service';
import { DialogRef } from '@app/shared/components/dialog';

@Component({
  selector: 'omg-smart-draft-limitations-modal',
  templateUrl: './smart-draft-limitations-modal.html',
  styleUrls: ['./smart-draft-limitations-modal.component.scss'],
})
export class SmartDraftLimitationsModalComponent {
  doNotShowSmartDraftLimitationsAgain;
  private destroyRef = inject(DestroyRef);
  constructor(
    public dialogRef: DialogRef<SmartDraftLimitationsModalComponent, boolean>,
    private smartDraftService: SmartDraftService,
  ) {}

  close() {
    this.dialogRef.close(false);
  }

  acknowledge() {
    if (this.doNotShowSmartDraftLimitationsAgain) {
      this.smartDraftService
        .suppressSmartDraftLimitationsWarning()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
    this.close();
  }
}
