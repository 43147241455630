// @ts-strict-ignore
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output, TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { QuillEditorComponent, QuillFormat, QuillModules } from 'ngx-quill';
import { combineLatest, fromEvent, merge, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  scan,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { SearchIndex } from '@app/core';
import { TrackEventProperties } from '@app/core/analytics/analytics.type';
import { Template } from '@app/modules/messaging/shared/template-insertion.type';

import {
  getInlineInsertionPosition,
  InsertionPositionStyle,
  InsertionPreviewAlignment,
  InsertionState,
  InsertionTriggerConfiguration,
  isCurrentCharIndexGreaterThanTriggerIndex,
  isNotInlineInsertionScrollEvent,
  isValidInsertionTrigger,
  isWhitespace,
  QuillContentChangedEvent,
  searchTermReducer,
  searchTermReducerInitialState,
} from './inline-insertion.utils';

@Component({
  selector: 'omg-inline-insertion-container',
  templateUrl: './inline-insertion-container.component.html',
  styleUrls: ['./inline-insertion-container.component.scss'],
})
export class InlineInsertionContainerComponent implements AfterViewInit {
  @ViewChild(QuillEditorComponent) quillInstance: QuillEditorComponent;
  @ViewChild('editor') quillElement: any;

  @Input() customButtons!: TemplateRef<any>;
  @Input() readOnly = false;
  @Input() modules: QuillModules;
  @Input() format: QuillFormat;
  @Input() formats: string[];
  @Input() scrollingContainer: HTMLElement | string;
  @Input() placeholder: string;
  @Input() control: UntypedFormControl;
  @Input() singleLine: boolean;
  @Input() classes: string;
  @Input() dataCy: string;
  @Input() insertionTriggers: InsertionTriggerConfiguration;
  @Input() insertionEventProps: Partial<TrackEventProperties>;
  @Input() templateType: string;
  @Input() maxLength: number = null;
  @Input() required = false;
  @Output() editorInitialized = new EventEmitter();
  @Output() editorBlur = new EventEmitter<void>();
  @Output() editorFocus = new EventEmitter<void>();
  @Output() editorFocusedOut = new EventEmitter<void>();
  @Output() contentChanged = new EventEmitter<QuillContentChangedEvent>();
  @Output() insertionActiveStateChanged = new EventEmitter<boolean>();

  private destroyInlineInsertion = new Subject<void>();
  private inlineInsertionDestroyed$ = this.destroyInlineInsertion.pipe(
    mapTo(false),
  );
  private inlineInsertionActivated$: Observable<boolean>;

  activeSearchIndex$: Observable<SearchIndex>;
  isInlineInsertionActive$: Observable<boolean>;
  insertionState$: Observable<InsertionState>;
  insertionPositionStyle$: Observable<InsertionPositionStyle>;
  insertionPreviewAlignment$: Observable<InsertionPreviewAlignment>;

  selectedTemplate: Template;
  isQuillInFocus: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.setActiveSearchIndex();
    this.setInsertionActiveState();
    this.setState();
    this.setInsertionPosition();
    this.cd.detectChanges();
  }

  itemSelected(template: Template) {
    this.selectedTemplate = template;
    this.destroyInlineInsertion.next();
  }

  editorBlurred() {
    this.isQuillInFocus = false;
    this.editorBlur.emit();
    this.destroyInlineInsertion.next();
  }

  editorFocused() {
    this.editorFocus.emit();
    this.isQuillInFocus = true;
  }

  // (onBlur)="editorBlurred()" doesnt fire when a cta is clicked while cursor is still in the quill-editor.
  // To circumvent, we're listening for blur events on 'onEditorCreated'
  editorCreated(event: any) {
    this.editorInitialized.emit();
    fromEvent(event.root, 'blur').subscribe(() => {
      this.editorBlurred();
    });
  }

  focus() {
    this.quillInstance.quillEditor.focus();
  }

  private setActiveSearchIndex() {
    this.activeSearchIndex$ = fromEvent<KeyboardEvent>(
      this.quillElement.elementRef.nativeElement,
      'keyup',
    ).pipe(
      filter(
        isValidInsertionTrigger(this.quillInstance, this.insertionTriggers),
      ),
      map(event => this.insertionTriggers[event.key]),
      shareReplay(1),
    );
  }

  private setInsertionActiveState() {
    this.inlineInsertionActivated$ = this.activeSearchIndex$.pipe(mapTo(true));
    this.isInlineInsertionActive$ = merge(
      this.inlineInsertionActivated$,
      this.inlineInsertionDestroyed$,
    ).pipe(
      startWith(false),
      tap(isActive => this.insertionActiveStateChanged.emit(isActive)),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  private setState() {
    this.insertionState$ = this.isInlineInsertionActive$.pipe(
      filter(isActive => !!isActive),
      map(() => this.getStartIndex()),
      switchMap((initialStartIndex: number) =>
        fromEvent(this.quillElement.elementRef.nativeElement, 'keyup').pipe(
          map((event: KeyboardEvent) => event.key),
          tap(this.destroyInsertionIfEscapeKeyPressed),
          scan(
            searchTermReducer(this.quillInstance),
            searchTermReducerInitialState(initialStartIndex),
          ),
          tap(this.destroyInsertionIfCursorPositionWrong),
          distinctUntilKeyChanged('searchTerm'),
          startWith(searchTermReducerInitialState(initialStartIndex)),
          takeUntil(this.inlineInsertionDestroyed$),
        ),
      ),
      shareReplay(1),
    );
  }

  private setInsertionPosition() {
    const scrollEvents = fromEvent(document, 'scroll', {
      capture: true,
    }).pipe(filter(isNotInlineInsertionScrollEvent), startWith(null));

    const insertionPosition$ = combineLatest([
      this.insertionState$,
      scrollEvents,
    ]).pipe(
      map(([{ startIndex }]) =>
        getInlineInsertionPosition(
          startIndex,
          this.quillInstance,
          this.quillElement.elementRef.nativeElement,
          window.innerHeight,
        ),
      ),
      tap(insertionStyles => {
        if (insertionStyles.destroyInsertion) {
          this.destroyInlineInsertion.next();
        }
      }),
      shareReplay(1),
    );

    this.insertionPositionStyle$ = insertionPosition$.pipe(
      map(position => position.insertionStyle),
    );

    this.insertionPreviewAlignment$ = insertionPosition$.pipe(
      map(position => position.insertionPreviewAlignment),
    );
  }

  private destroyInsertionIfEscapeKeyPressed = (key: string) => {
    if (key === 'Escape') {
      this.destroyInlineInsertion.next();
    }
  };

  private destroyInsertionIfCursorPositionWrong = (state: InsertionState) => {
    if (
      isWhitespace(state.searchTerm) ||
      isCurrentCharIndexGreaterThanTriggerIndex(
        this.quillInstance,
        state.startIndex,
      )
    ) {
      this.destroyInlineInsertion.next();
    }
  };

  private getStartIndex = (): number =>
    this.quillInstance.quillEditor.getSelection().index;
}
