import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { CollapseDirective } from '@app/shared';

@Component({
  selector: 'omg-cart-item-more-options',
  templateUrl: './cart-item-more-options.component.html',
})
export class CartItemMoreOptionsComponent implements OnInit {
  @Input() collapseProvider: CollapseDirective;
  @Input() dispenseAsWritten: UntypedFormControl;
  @Input() fillAfterDate: UntypedFormControl;
  @Input() fillAfterDateRequired: boolean;
  @Input() fillAfterDateVisible: boolean;
  @Input() fillAfterInputName: string;
  @Input() maxEarliestFillDate: string;
  @Input() minEarliestFillDate: string;
  @Input() notesToPharmacist: UntypedFormControl;
  @Input() notesToPharmacistRequired: boolean;
  @Input() notesAddenda: string;
  @Input() maxNotesLength = 210;

  @Output() recalculateDates = new EventEmitter<void>();

  notesToPharmacistPlaceholder: string;

  constructor() {}

  ngOnInit(): void {
    this.notesToPharmacistPlaceholder = this.notesToPharmacistRequired
      ? `You must note patient's medical need for GHB here.`
      : `Optional`;
  }
}
