import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Commentable } from '@app/modules/comments/shared/comments.type';

import { Renewal } from '../../shared/renewals.type';
import { RenewalActions, RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-renewal-item-subheader',
  templateUrl: './renewal-item-subheader.component.html',
  styleUrls: ['./renewal-item-subheader.component.scss'],
})
export class RenewalItemSubheaderComponent implements OnInit {
  @Input() renewal: Renewal;

  renewalPositionPhrase$: Observable<string>;
  showComments = false;
  commentable: Commentable;

  constructor(
    private renewalActions: RenewalActions,
    private renewalSelectors: RenewalSelectors,
  ) {}

  ngOnInit() {
    this.setupSelectors();
    if (this.renewal.totalComments > 0) {
      this.toggleComments();
    }
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  updateCommentCount(action: 'add' | 'remove') {
    const adjustByCount = action === 'add' ? 1 : -1;
    this.renewalActions.updateRenewalCommentTotal(
      this.renewal.id,
      this.renewal.totalComments + adjustByCount,
    );
  }

  private setupSelectors() {
    const { id, totalComments } = this.renewal;
    this.commentable = { id, totalComments, commentableType: 'renewals' };
    this.renewalPositionPhrase$ = this.renewalSelectors.ids.pipe(
      map(
        (ids: number[]) =>
          `Renewal ${ids.indexOf(this.renewal.id) + 1} of ${ids.length}`,
      ),
    );
  }
}
