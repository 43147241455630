import { NgModule } from '@angular/core';
import { HotkeyModule } from 'angular2-hotkeys';

import { ThemeDirective } from '@app/core/theme/theme.directive';

import { SyncInputDirective } from '../components/sync-input/sync-input.directive';
import { AnalyticsDirective } from './analytics.directive';
import { CopyToClipboardDirective } from './copy-to-clipboard.directive';
import { DetectResizeDirective } from './detect-resize/detect-resize.directive';
import { DetectResizeService } from './detect-resize/detect-resize.service';
import { FocusOnDirective } from './focus/focus-on.directive';
import { FocusProviderDirective } from './focus/focus-provider.directive';
import { LegacyUiHrefDirective } from './legacy-ui-href.directive';
import { ShortcutClickDirective } from './shortcut-click/shortcut-click.directive';
import { StopEventDirective } from './stop-event.directive';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { TrackScrollDirective } from './track-scroll.directive';

const directives = [
  CopyToClipboardDirective,
  FocusOnDirective,
  FocusProviderDirective,
  LegacyUiHrefDirective,
  ShortcutClickDirective,
  StopEventDirective,
  SyncInputDirective,
  TooltipDirective,
  DetectResizeDirective,
  ThemeDirective,
  TrackScrollDirective,
  AnalyticsDirective,
];

@NgModule({
  imports: [HotkeyModule],
  declarations: [...directives],
  exports: [HotkeyModule, ...directives],
  providers: [DetectResizeService],
})
export class DirectivesModule {}
