import { PatientOffice, Profile, ProfileIdentity } from '@app/core';
import { DropdownItem } from '@app/shared';

import { LetterTemplate, UserSignatureInfo } from './letter.type';

export function mapLetterTemplatesToDropdownItems(
  templates: LetterTemplate[],
): DropdownItem[] {
  return templates.map(mapLetterTemplateToDropdownItem);
}
export const mapLetterTemplateToDropdownItem = (
  letterTemplate: LetterTemplate,
): DropdownItem => {
  return {
    value: letterTemplate.id,
    label: letterTemplate.name,
  };
};

export const buildOfficeContactNumbers = (office: PatientOffice): string => {
  if (!office) {
    return '';
  }
  const phone = office.phone && `ph: ${office.phone}`;
  const fax = office.fax && `fax: ${office.fax}`;
  return phone && fax ? `${phone} · ${fax}` : phone || fax;
};

export const buildUserSignature = (
  profileInfo: Profile & { primaryIdentity: ProfileIdentity },
): UserSignatureInfo => {
  if (!profileInfo) {
    return { displayName: '' };
  }
  const displayName = profileInfo.primaryIdentity.name;
  if (profileInfo.roles.includes('provider')) {
    return { npi: profileInfo.npi, displayName };
  } else if (profileInfo.roles.includes('admin')) {
    return { displayName: `${displayName}, Administrative Assistant` };
  } else if (profileInfo.roles.includes('phlebotomist')) {
    return { displayName: `${displayName}, Phlebotomist` };
  }
  return { displayName };
};
