<div om-layout
     om-layout-fill
     om-layout-align="space-between center"
     om-layout-gutter
     data-cy="smart-draft-banner"
     class="banner -instructional padding-half -bottom-margin">
  <div om-layout
       om-layout-gutter-large
       om-layout-align="start center">
    <div om-layout
         om-layout-gutter-mini
         om-layout-align="start center">
      <span class="om-icon icon-magic"></span>
      <b>{{smartDraftInfoTitle}}</b>
    </div>
    <div om-layout
         om-layout-gutter
         om-layout-align="start center">
      <span>Easily write C-I-CARE message</span>
      <span [omgPopoverTriggerFor]="smartDraftPopover"
            triggerOn="hover">
        <span class="om-icon -small icon-alert-info"></span>
      </span>
    </div>
  </div>
  <button omgButton
          variant="primary"
          class="-thin"
          data-cy="smart-draft-sidecar-button"
          (click)="openSmartDraft.emit()">Try it</button>
</div>

<omg-popover [title]="smartDraftInfoTitle"
             #smartDraftPopover>
  <div om-layout="vertical"
       om-layout-gutter-large
       data-cy="smart-draft-info-popover"
       class="padding-normal">
    <p>Smart Draft uses AI to help you draft empathetic, informed, and timely messages with patients.</p>
    <div om-layout="vertical">
      <b>This feature is in Beta</b>
      <p>That means it’s close to ready, but not quite. We expect minor issues or errors. Your feedback will help us get
        it
        ready to share with everyone. Thank you!</p>
    </div>
  </div>
</omg-popover>
