import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import { sortProblemsByClinicalDescription } from '../../shared/summaries-utils';
import { SummaryProblem } from '../../shared/summaries.type';

@Component({
  selector: 'omg-linked-assessment-plan',
  templateUrl: './linked-assessment-plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedAssessmentPlanComponent implements OnInit {
  useEnhancedAP = false;
  @Input() signed: boolean;

  @Input() get assessedProblems(): SummaryProblem[] {
    return this._assessedProblems;
  }

  constructor(private launchDarklyService: LaunchDarklyService) {}

  set assessedProblems(assessedProblems: SummaryProblem[]) {
    this._assessedProblems = sortProblemsByClinicalDescription([
      ...assessedProblems,
    ]);
  }

  @Output() unlink = new EventEmitter<SummaryProblem>();

  private _assessedProblems: SummaryProblem[];

  ngOnInit() {
    this.useEnhancedAP = this.launchDarklyService.variation(
      FeatureFlagNames.enhancedAPinSummaries,
      false,
    );
  }

  trackByFn = (index, summaryProblem: SummaryProblem) =>
    summaryProblem.id || index;

  deleteFromNote(problem: SummaryProblem) {
    this.unlink.emit(problem);
  }
}
