import { ValidatorFn, Validators } from '@angular/forms';

import { DynamicFormGroup } from '@app/utils/forms/base';

export class HistoricalProcedureCaptureForm extends DynamicFormGroup {
  constructor() {
    super();
    this.addControls();
  }

  private addControls() {
    const controls: {
      name: string;
      validators?: ValidatorFn[];
      defaultValue?: any;
    }[] = [
      { name: 'procedureFhirId', defaultValue: null },
      { name: 'procedureCode', validators: [Validators.required] },
      { name: 'procedureDate', validators: [Validators.required] },
      { name: 'procedureResult', validators: [Validators.required] },
    ];

    controls.forEach(control =>
      this.addControl({
        name: control.name,
        defaultValue: control.defaultValue || null,
        validators: control.validators,
      }),
    );
  }
}
