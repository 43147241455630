import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core/api/api.service';

import { mapNoteTypesResponseToEntity } from './note-types-api-mapper';
import { NoteTypesResponse } from './note-types-api.type';
import { NoteTypes } from './note-types.type';

@Injectable()
export class NoteTypesService {
  private route = '/v2/admin/note_types';

  constructor(private api: ApiService) {}

  getAll(patientId: number): Observable<NoteTypes> {
    return this.api
      .get<NoteTypesResponse>(this.route, { patient_id: patientId })
      .pipe(map(mapNoteTypesResponseToEntity));
  }
}
