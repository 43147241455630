import { Pipe, PipeTransform } from '@angular/core';

export interface PharmacyAddressInput {
  displayAddressStreet?: string;
  city: string;
  state: string;
  zip: string;
}

@Pipe({
  name: 'omgPharmacyAddress',
})
export class PharmacyAddressPipe implements PipeTransform {
  transform(address: PharmacyAddressInput): string {
    const displayAddress =
      address.displayAddressStreet && address.displayAddressStreet !== 'Unknown'
        ? `${address.displayAddressStreet} `
        : '';
    return `${displayAddress}${address.city}, ${address.state} ${address.zip}`;
  }
}
