<div class="omg-dialog-container"
     omgTheme>
  <ng-template [ngTemplateOutlet]="rxHistoryWithPDMP">
  </ng-template>
</div>

<ng-template #rxHistoryTitle>
  <div omgDialogTitleDirective>
    <h5 class="prescription-history-title">Prescription History</h5>
    <button type="button"
            class="om-icon icon-close clickable"
            (click)="onClose()"></button>
  </div>
</ng-template>
<ng-template #rxHistoryContent>
  <div *ngIf="prescriptionHistory$ | ngrxPush as history; else loadingHistory">
    <div *ngIf="history.length; else noHistory">
      <omg-section-title [altBackgroundColor]="true">
        <span om-flex="2"></span>
        <span om-flex="13"
              class="padding-normal-horizontal">Date/Time</span>
        <span om-flex="25"
              class="padding-normal-horizontal">Medication</span>
        <span om-flex="25"
              class="padding-normal-horizontal">Destination</span>
        <span om-flex="20"
              class="padding-normal-horizontal">Signed By</span>
        <span om-flex="15"
              class="padding-normal-horizontal">Status</span>
      </omg-section-title>
      <omg-collapse class="row -clickable"
                    #rxHistoryItemCollapseRef="omgCollapse"
                    *ngFor="let rx of history; trackBy:trackByFn">
        <div om-layout
             om-layout-align="start"
             omgCollapseToggle>
          <div om-flex="2"
               om-layout
               om-layout-gutter
               om-layout-align="end">
            <omg-collapsed disableAnimation="true"><span class="pi pi-chevron-right"></span>
            </omg-collapsed>
            <omg-expanded disableAnimation="true"><span class="pi pi-chevron-down"></span>
            </omg-expanded>
          </div>
          <div om-flex="13"
               class="padding-normal-horizontal clickable">
            {{ rx.cartCompleteAt | date:'short' }}
          </div>
          <div om-flex="25"
               class="padding-normal-horizontal">
            <b>{{ rx.medicationDescription }}</b>
          </div>
          <div om-flex="25"
               class="padding-normal-horizontal">
            {{ getDestinationText(rx) }}
          </div>
          <div om-flex="20"
               class="capitalize
                        padding-normal-horizontal">
            {{ rx.signedByName }}
          </div>
          <div class="padding-normal-horizontal"
               om-flex="10">
            <div [ngClass]="{'-failed': rx.status === 'failed'}"
                 class="capitalize">
              {{ rx.status }}
            </div>
          </div>
          <div om-flex="5"
               class="padding-normal-horizontal">
            <div *ngIf="!!rx.medicationPrescriptionTransmission && !rx.dispensableControlledSubstance">
              <omg-prescription-pdf-link [transmissionId]="rx.medicationPrescriptionTransmission.id">
              </omg-prescription-pdf-link>
            </div>
          </div>
        </div>
        <omg-expanded>
          <div om-layout>
            <div om-flex="2"></div>
            <div om-flex="13"
                 class="padding-normal-horizontal"></div>
            <div om-flex="25"
                 om-layout="vertical"
                 om-layout-align="start"
                 class="padding-normal-horizontal">
              <div>{{ rx.medicationDirections }}</div>
              <div><b>Qty: </b>{{ rx.quantity }}, <b>Refills: </b>{{ rx.refills }}</div>
              <div *ngIf="!!rx.notesToPharmacist && rx.notesToPharmacist.length">
                <b>Note: </b>{{ rx.notesToPharmacist }}
              </div>
            </div>
            <div om-flex="25"
                 om-layout="vertical"
                 om-layout-align="start"
                 class="padding-normal-horizontal">
              <div *ngIf="!!rx.pharmacy">
                <div>{{ rx.pharmacy.addressStreet }}</div>
                <div>{{ rx.pharmacy.addressCsz }}</div>
                <div>Phone: {{ rx.pharmacy.workPhoneNumber | omgPhoneNumber }}</div>
                <div>Fax: {{ rx.pharmacy.faxPhoneNumber | omgPhoneNumber }}</div>
              </div>
            </div>
            <div om-flex="20"
                 om-layout="vertical"
                 om-layout-align="start"
                 class="padding-normal-horizontal gray">
              <div *ngIf="rx.signedByName !== rx.signedOnBehalfOfName">on behalf of {{ rx.signedOnBehalfOfName }}
              </div>
              <div *ngIf="!!rx.prescribingCredential">(DEA: {{ rx.prescribingCredential.deaLicenseNumber }}, Lic:
                {{ rx.prescribingCredential.licenseNumber }})
              </div>
            </div>
            <div om-flex="15"
                 om-layout="vertical"
                 om-layout-align="start"
                 class="padding-normal-horizontal">
            </div>
          </div>
        </omg-expanded>
      </omg-collapse>
    </div>
  </div>
</ng-template>
<ng-template #loadingHistory>
  <div class="empty-tab-message"
       om-layout-align="space-around center"
       om-layout-gutter-large>
    <i class="fa fa-lg fa-spinner fa-pulse"></i>
  </div>
</ng-template>
<ng-template #noHistory>
  <div class="empty-tab-message">No Prescription History Found.</div>
</ng-template>
<ng-template #rxHistoryWithPDMP>
  <button type="button"
          class="om-icon icon-close clickable overlay-top"
          (click)="onClose()"
          data-cy="rx-history-close-button"></button>
  <omg-tabs data-cy="rx-history-tabs"
            altTabHeight="true">
    <omg-tab [heading]="prescriptionHistoryTabs.history.heading"
             noTextTransformHeading="true"
             [isActive]="currentHistoryTab === prescriptionHistoryTabs.history.id"
             data-cy="rx-history-tab">
      <div omgDialogContentDirective
           class="data-table">
        <ng-template [ngTemplateOutlet]="rxHistoryContent">
        </ng-template>
      </div>
    </omg-tab>
    <omg-tab [heading]="prescriptionHistoryTabs.pdmp.heading"
             noTextTransformHeading="true"
             [isActive]="currentHistoryTab === prescriptionHistoryTabs.pdmp.id"
             data-cy="pdmp-tab">
      <omg-pdmp-tab-contents [rxCartId]="rxCartId"></omg-pdmp-tab-contents>
    </omg-tab>
  </omg-tabs>
</ng-template>
