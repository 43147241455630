import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { CollapsibleBannerComponent } from '@app/shared/components/collapsible-banner/collapsible-banner.component';

import { DocumentSummaryFeedbackFieldInput } from '../../../../../graphql/onelife.type';

type SummaryAccuracyOption = {
  label: string;
  value: SummaryAccuracyValue;
};

type SummaryAccuracyValue = '0' | '1' | '2';

interface DocumentSummaryFeedbackForm {
  accuracy: FormControl<SummaryAccuracyValue | null>;
  freetext: FormControl<string | null>;
}

export interface DocumentSummaryFeedbackEvent {
  summaryId: string;
  fields: DocumentSummaryFeedbackFieldInput[];
}

@Component({
  selector: 'omg-document-summary-feedback',
  templateUrl: './document-summary-feedback.component.html',
  styleUrls: ['./document-summary-feedback.component.scss'],
})
export class DocumentSummaryFeedbackComponent {
  form: FormGroup<DocumentSummaryFeedbackForm>;
  bannerThanksTextVisible: boolean = false;
  reviewTextVisible: boolean = true;

  @Input()
  summaryId: string;

  @Output()
  readonly feedbackSubmitted = new EventEmitter<DocumentSummaryFeedbackEvent>();

  @ViewChild(CollapsibleBannerComponent)
  banner: CollapsibleBannerComponent;

  readonly options: SummaryAccuracyOption[] = [
    {
      label: 'The summary is accurate and I found it useful',
      value: '0',
    },
    {
      label: 'The summary is accurate, but I did not find it useful',
      value: '1',
    },
    {
      label: 'The summary seems inaccurate',
      value: '2',
    },
  ];

  constructor(private formBuilder: FormBuilder) {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      accuracy: new FormControl<SummaryAccuracyValue | null>(null),
      freetext: new FormControl<string | null>(null),
    });
  }

  /***
   * Transform the presented and selected values into the input types
   * expected by our backend. The values are extracted from the form components.
   */
  generateFeedbackInputs(): DocumentSummaryFeedbackFieldInput[] {
    const fields: DocumentSummaryFeedbackFieldInput[] = [];

    const accuracy = this.form.controls.accuracy.value;
    if (accuracy) {
      const selectedOption = this.options.find(
        option => option.value === accuracy,
      );
      if (selectedOption) {
        fields.push({
          name: 'Impression',
          options: this.options,
          submitted: [selectedOption],
        });
      }
    }

    const freetext = this.form.controls.freetext.value;
    if (freetext) {
      fields.push({
        name: 'Tell us Why',
        options: [],
        submitted: [
          {
            label: 'Tell us Why',
            value: freetext,
          },
        ],
      });
    }

    return fields;
  }

  onSubmit(): void {
    const event: DocumentSummaryFeedbackEvent = {
      summaryId: this.summaryId,
      fields: this.generateFeedbackInputs(),
    };
    this.feedbackSubmitted.emit(event);
    this.triggerSubmissionAnimation();
  }

  /***
   * Flips the properties that cause the "Thank you" text to appear and transition out.
   */
  triggerSubmissionAnimation(): void {
    this.bannerThanksTextVisible = true;
    this.reviewTextVisible = false;
    this.banner.collapse();
    setTimeout(() => {
      this.bannerThanksTextVisible = false;
      setTimeout(() => {
        this.reviewTextVisible = true;
      }, 550);
    }, 2000);
  }

  onCancel(): void {
    this.banner.collapse();
  }

  onExpandChange(expanded: boolean): void {
    if (!expanded) {
      this.resetFormValues();
    }
  }

  resetFormValues(): void {
    this.form.reset();
  }
}
