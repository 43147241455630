import { Injectable } from '@angular/core';
import { MutationResult } from 'apollo-angular';
import { get } from 'lodash';
import { map } from 'rxjs/operators';

import {
  CreateSmartDraftMutation,
  CreateSmartDraftMutationService,
  GetSmartDraftQueryService,
  SaveSmartDraftFeedbackMutationService,
  SuppressSmartDraftLimitationsWarningMutationService,
} from '@app/modules/messaging/shared/smart-draft.onelife.generated';

import { SmartDraftFeedback } from '../../../../graphql/onelife.type';

@Injectable()
export class SmartDraftService {
  constructor(
    private createSmartDraftService: CreateSmartDraftMutationService,
    private getSmartDraftService: GetSmartDraftQueryService,
    private saveSmartDraftFeedbackService: SaveSmartDraftFeedbackMutationService,
    private suppressSmartDraftLimitationsWarningService: SuppressSmartDraftLimitationsWarningMutationService,
  ) {}

  create(replyInstructions: string, threadId: string) {
    return this.createSmartDraftService
      .mutate({
        input: {
          replyInstructions,
          threadId,
        },
      })
      .pipe(
        map((smartDraftResponse: MutationResult<CreateSmartDraftMutation>) =>
          get(smartDraftResponse, 'data.createSmartDraft.smartDraft.id', null),
        ),
      );
  }

  get(id: string) {
    return this.getSmartDraftService.watch({ id });
  }

  saveFeedback(smartDraftId: string, feedback: SmartDraftFeedback) {
    return this.saveSmartDraftFeedbackService.mutate({
      input: {
        smartDraftId,
        feedback,
      },
    });
  }

  suppressSmartDraftLimitationsWarning() {
    return this.suppressSmartDraftLimitationsWarningService.mutate({
      input: {},
    });
  }
}
