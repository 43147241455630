import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core';

import { mapLabDataResponseToEntity } from './lab-data-api-mappers';
import { LabDataResponse } from './lab-data-api.type';
import { LabData } from './lab-data.type';

@Injectable({
  providedIn: 'root',
})
export class LabDataService {
  readonly LAB_DATA_ENDPOINT = '/v2/admin/lab_data';

  constructor(private api: ApiService) {}

  get(id: number): Observable<LabData> {
    return this.api
      .get<LabDataResponse>(`${this.LAB_DATA_ENDPOINT}/${id}`)
      .pipe(map(mapLabDataResponseToEntity));
  }
}
