import { NgModule } from '@angular/core';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs-v2';

import { ApplyFuncEachPipe } from '@app/shared/pipes/apply-func-each.pipe';
import { ApplyFuncPipe } from '@app/shared/pipes/apply-func.pipe';
import { FormatConfidencePipe } from '@app/shared/pipes/format-confidence.pipe';

import { DateAgoPipe } from './date-ago.pipe';
import { DatePipe } from './date.pipe';
import { DaysToDistancePipe } from './days-to-distance.pipe';
import { EmphasizePipe } from './emphasize.pipe';
import { InjectSlashPipe } from './inject-slash.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { PercentilePipe } from './percentile.pipe';
import { PharmacyAddressPipe } from './pharmacy-address.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { PossessivePipe } from './possessive.pipe';
import { RemainingCharactersPipe } from './remaining-characters.pipe';
import { SafePipe } from './safe.pipe';
import { ScrubTextDirective, ScrubTextPipe } from './scrub-text';
import { TruncateTextPipe } from './truncate-text.pipe';
import { WrapTextPipe } from './wrap-text.pipe';

const pipes = [
  DateAgoPipe,
  DatePipe,
  DaysToDistancePipe,
  EmphasizePipe,
  InjectSlashPipe,
  LinkifyPipe,
  PhoneNumberPipe,
  PossessivePipe,
  RemainingCharactersPipe,
  SafePipe,
  ScrubTextPipe,
  WrapTextPipe,
  PharmacyAddressPipe,
  TruncateTextPipe,
  PercentilePipe,
  FormatConfidencePipe,
  ApplyFuncPipe,
  ApplyFuncEachPipe,
];

@NgModule({
  imports: [NgxLinkifyjsModule.forRoot()],
  exports: [...pipes],
  declarations: [...pipes, ScrubTextDirective],
  providers: [...pipes],
})
export class PipesModule {}
