// https://docs.google.com/spreadsheets/d/1CBTpVSBn-rXUAThEDSf2tG8FaEILOqK8WxzO9cll_Z8/edit?usp=sharing
export const SummaryExcludeListByNoteType = [
  'Archive Email',
  'Colposcopy Diagram',
  'Consent Form',
  'Controlled Rx Renewal Request (FAXED)',
  'Diagnostic Test Order',
  'Electrocardiogram (ECG / EKG)',
  'Failed Controlled Substance eRx',
  'Failed Direct Message',
  'Fill in information',
  'Fill in Lab information',
  'Group Visit Confidentiality Agreement',
  'Hard Copy Controlled Rx',
  'Health Current Opt Back-In Form',
  'Health Current Opt Out Form',
  'Health Information Exchange Patient Consent Form',
  'Incomplete Laboratory Report',
  'Intake Form',
  'IUD Consent Form',
  'Lab Result',
  'Lab Result: Xpert Xpress SARS-CoV-2',
  'Laboratory Order',
  'Laboratory Test Add On',
  'Manual Consult Order',
  'Manual Test Order',
  'Medical Records Release Form',
  'Medicare Advance Beneficiary Notice (ABN) of Non-Coverage Form',
  'Medicare Health Risk Assessment (HRA)',
  'NY State MOLST form',
  'Outside Laboratory Order',
  'Pap Order',
  'Patient Survey',
  'Prescription Drug Monitoring Program (PDMP) Report',
  'Procedure Order',
  'Redrock Consent Form',
  'Registration Form',
  'Return to Work Patient Survey',
  'Rx Clarification',
  'Rx Phone Request',
  'Rx Renewal',
  'Rx Web Renewal',
  'Rx Web Renewal (Mobile App)',
  'School camp health forms',
  'Service Ticket',
  'Specialist: Referral Confirmation',
  'Vaccine Order',
  'Verbal Rx Request',
];
