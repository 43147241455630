import { Attribute, Directive, ElementRef, HostBinding } from '@angular/core';

import { camelCase } from '@app/utils';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseInput {
  @HostBinding('attr.autocomplete') autoComplete = 'off';
  @HostBinding('attr.name') nameAttr;

  constructor(
    private el: ElementRef,
    @Attribute('formControlName') private controlName?,
    @Attribute('name') private name?,
  ) {
    this.nameAttr =
      this.name ||
      this.controlName ||
      (this.el.nativeElement.parentElement &&
        camelCase(this.el.nativeElement.parentElement.textContent)) || // assuming parent is a label tag
      '';
  }
}
