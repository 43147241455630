<div class="thread-summary"
     data-cy="ai-thread-summary">

  <omg-collapse [expanded]=true>
    <omg-collapse-toggle>
      <div om-layout
           om-layout-align="start center">
        <i class="om-icon icon-magic"></i>
        <span class="thread-summary-title">AI Conversation Summary</span>
        <omg-collapsed disableAnimation="true"><i class="fa fa-chevron-down"></i></omg-collapsed>
        <omg-expanded disableAnimation="true"><i class="fa fa-chevron-up"></i></omg-expanded>
      </div>
    </omg-collapse-toggle>

    <omg-expanded>
      {{(summary$ | ngrxPush)}}
    </omg-expanded>
  </omg-collapse>
</div>
