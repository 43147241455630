import { FsaAction } from '@app/core';
import { StateActions } from '@app/core/store/shared/state';
import {
  Renewal,
  RenewalCart,
  RenewalCartResponse,
  RenewalCheckoutRequestData,
} from '@app/features/renewals/shared/renewals.type';
import {
  GetPrescriberCredentialsPayload,
  PrescriberCredentials,
  PrescriberCredentialsErrorPayload,
} from '@app/modules/shared-rx/prescriber-credential.type';

export enum RenewalActionTypes {
  LoadRenewalCart = '[Renewal] Load Renewal Cart',
  LoadRenewalCartSuccess = '[Renewal] Load Renewal Cart Success',
  LoadRenewalCartError = '[Renewal] Load Renewal Cart Error',
  UpdateRenewalCommentTotal = '[Renewal] Update Renewal Comments Total',
  CheckoutRenewalCart = '[Renewal] Checkout Renewal Cart',
  CheckoutRenewalCartSuccess = '[Renewal] Checkout Renewal Cart Success',
  CheckoutRenewalCartError = '[Renewal] Checkout Renewal Cart Error',
  UpdateCartState = '[Renewal] Update Cart State',
  LoadPrescriberCredentials = '[Renewal] Load Prescriber Credentials',
  LoadPrescriberCredentialsSuccess = '[Renewal] Load Prescriber Credentials Success',
  LoadPrescriberCredentialsError = '[Renewal] Load Prescriber Credentials Error',
}

type RenewalError = any;
type RenewalCount = number;
interface UpdateCommentCount {
  id: number;
  totalComments: number;
}

interface RenewalCheckoutVerificationPayload {
  password: string;
}

export class LoadRenewalCart implements FsaAction {
  readonly type = RenewalActionTypes.LoadRenewalCart;

  constructor() {}
}

export class LoadRenewalCartSuccess implements FsaAction<RenewalCartResponse> {
  readonly type = RenewalActionTypes.LoadRenewalCartSuccess;
  error = false;

  constructor(public payload: RenewalCartResponse) {}
}

export class LoadRenewalCartError implements FsaAction<RenewalError> {
  readonly type = RenewalActionTypes.LoadRenewalCartError;
  error = true;

  constructor(public payload: RenewalError) {}
}

export class UpdateRenewalCommentTotal
  implements FsaAction<UpdateCommentCount>
{
  readonly type = RenewalActionTypes.UpdateRenewalCommentTotal;
  error = false;

  constructor(public payload: UpdateCommentCount) {}
}

export class CheckoutRenewalCart
  implements FsaAction<RenewalCheckoutRequestData>
{
  readonly type = RenewalActionTypes.CheckoutRenewalCart;
  error = false;

  constructor(public payload: RenewalCheckoutRequestData) {}
}

export class CheckoutRenewalCartSuccess implements FsaAction<any> {
  readonly type = RenewalActionTypes.CheckoutRenewalCartSuccess;
  error = false;

  constructor(public payload: any) {}
}

export class CheckoutRenewalCartError implements FsaAction<any> {
  readonly type = RenewalActionTypes.CheckoutRenewalCartError;
  error = true;

  constructor(public payload: any) {}
}

export class UpdateCartState implements FsaAction<any> {
  readonly type = RenewalActionTypes.UpdateCartState;

  constructor(public payload: Partial<RenewalCart>) {}
}

export class LoadPrescriberCredentials
  implements FsaAction<GetPrescriberCredentialsPayload>
{
  readonly type = RenewalActionTypes.LoadPrescriberCredentials;

  constructor(public payload: GetPrescriberCredentialsPayload) {}
}

export class LoadPrescriberCredentialsSuccess
  implements FsaAction<PrescriberCredentials>
{
  readonly type = RenewalActionTypes.LoadPrescriberCredentialsSuccess;
  error = false;

  constructor(public payload: PrescriberCredentials) {}
}

export class LoadPrescriberCredentialsError
  implements FsaAction<PrescriberCredentialsErrorPayload>
{
  readonly type = RenewalActionTypes.LoadPrescriberCredentialsError;
  error = true;

  constructor(public payload: PrescriberCredentialsErrorPayload) {}
}

export type RenewalCustomAction =
  | CheckoutRenewalCart
  | CheckoutRenewalCartError
  | LoadRenewalCart
  | LoadRenewalCartSuccess
  | LoadRenewalCartError
  | LoadPrescriberCredentials
  | LoadPrescriberCredentialsSuccess
  | LoadPrescriberCredentialsError
  | UpdateRenewalCommentTotal;

export class RenewalActions extends StateActions<Renewal> {
  loadRenewalCart() {
    this.store.dispatch(new LoadRenewalCart());
  }

  loadPrescriberCredentials(renewal: Renewal) {
    this.store.dispatch(new LoadPrescriberCredentials({ renewal }));
  }

  updateRenewalCommentTotal(id: number, totalComments: number) {
    this.store.dispatch(new UpdateRenewalCommentTotal({ id, totalComments }));
  }

  checkout(data: RenewalCheckoutRequestData) {
    this.store.dispatch(new CheckoutRenewalCart(data));
  }

  updateCartState(data: Partial<RenewalCart>) {
    this.store.dispatch(new UpdateCartState(data));
  }
}
