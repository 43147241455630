<div *ngIf="label"
     class="label"
     (click)=focus()>{{label}}</div>
<div class="radio-button-group"
     om-layout>
  <div *ngFor="let option of options"
       [ngClass]="{'alert' : showErrorState && buttonGroupControl.hasError && buttonGroupControl.errors?.required}"
       om-layout-fill>
    <label om-layout="vertical"
           om-layout-align="center center"
           om-layout-fill
           (click)="option.click && option.click()">
      <span om-layout="vertical"
            om-layout-align="center center"
            om-layout-fill
            [innerHtml]="option.label"></span>
      <input #buttonGroupOption
             type="radio"
             (click)="buttonGroupClick.emit($event); updateValueOnClick(option.value)"
             [value]="option.value"
             [checked]="option.checked"
             [formControl]="buttonGroupControl"
             [name]="radioGroupName"
             [id]="radioGroupName + '-' + option.value" />
    </label>
  </div>
</div>
<div class="caption">
  {{hintText}}
</div>
<div *ngIf="showErrorState && buttonGroupControl.hasError && buttonGroupControl.errors?.required"
     class="alert">
  <div>A selection for {{label}} is required</div>
</div>
