import { APP_INITIALIZER, Provider } from '@angular/core';
import { environment } from '@environments/environment';

import { VersionCheckService } from './version-check.service';

export function versionCheckFactory(versionChecker: VersionCheckService) {
  return () => {
    if (environment.versionChecker.enabled) {
      versionChecker.startInterval();
    }
  };
}

export const versionCheckProviders: Provider[] = [
  VersionCheckService,
  {
    provide: APP_INITIALIZER,
    useFactory: versionCheckFactory,
    deps: [VersionCheckService],
    multi: true,
  },
];
