<div om-layout="vertical"
     om-layout-gutter
     class="pdmp-dialogue-banner padding-normal">

  <div *ngIf="(reportStates$ | ngrxPush) as reportStates; else noLink">
    <a (click)="onShowReportClick()"
       class="pdmp-report-link om-link -plain-text"
       data-cy="pdmp-show-report-btn">
      View Prescription Drug Monitoring Program (PDMP) Report for {{ reportStates }}
    </a>
  </div>

  <ng-template #noLink>
    <div class="pdmp-report-nolink"
         data-cy="pdmp-report-nolink">
      Prescription Drug Monitor Program for {{ nonReportStates$ | ngrxPush }} not available
    </div>
  </ng-template>

  <div *ngIf="(nonReportStates$ | ngrxPush) as nonReportStates"
       data-cy="pdmp-non-reporting">
    <span *ngIf="reportStates$ | ngrxPush">
      PDMP Reporting for {{ nonReportStates }} not available.
    </span>
    Review state report{{nonReportStates.length > 2 ? 's': ''}} outside of 1Life
  </div>

  <omg-checkbox *ngIf="showCheckbox$ | ngrxPush"
                label="I have reviewed the report for {{ requiredStates$ | ngrxPush }}"
                plainLabel="true"
                [(ngModel)]="isChecked"
                (ngModelChange)="onConfirmReportViewedChange()"
                class="pdmp-dialogue-banner-confirm"
                data-cy="pdmp-confirm-checkbox">
  </omg-checkbox>
</div>
