const path = '/v2/admin';
const patientsRoute = '/patients';
const summaryRoute = '/chart/summaries';
const noteRoute = '/chart/notes';
const letterRoute = '/chart/letters';
const messagesRoute = '/chart/patient_timeline_posts';
const manualResultEntryRoute = '/chart/manual_result_entry';
const procedureInteractionRoute = '/chart/procedures';
const admissionsRoute = '/chart/admissions';

// Builds the route for summaries api calls
export const summariesRoute = (
  patientId: number,
  subRoute = '',
  suffix = '',
) => {
  if (subRoute.length) {
    subRoute = `/${subRoute}`;
  }
  if (suffix.length) {
    suffix = `/${suffix}`;
  }

  return `${path}${subRoute}/${patientId}${suffix}`;
};

// Builds the path for messaging router navigation
export const messagingPath = (
  patientId: number,
  messageId?: number,
  subRoute?: string,
) => {
  let resultRoute = `${patientsRoute}/${patientId}${messagesRoute}`;

  if (messageId) {
    resultRoute = `${resultRoute}/${messageId}`;
  }
  if (subRoute) {
    resultRoute = `${resultRoute}/${subRoute}`;
  }

  return resultRoute;
};

// Builds the path for summaries router navigation
export const summariesPath = (
  patientId: number,
  summaryId?: number | string | null,
  subRoute?: string,
) => {
  let resultRoute = `${patientsRoute}/${patientId}${summaryRoute}`;

  if (summaryId) {
    resultRoute = `${resultRoute}/${summaryId}`;
  }

  if (subRoute) {
    resultRoute = `${resultRoute}/${subRoute}`;
  }

  return resultRoute;
};

export const notesPath = (
  patientId: number,
  noteId?: number | null,
  subRoute?: string,
) => {
  let resultRoute = `${patientsRoute}/${patientId}${noteRoute}`;

  if (noteId) {
    resultRoute = `${resultRoute}/${noteId}`;
  }

  if (subRoute) {
    resultRoute = `${resultRoute}/${subRoute}`;
  }

  return resultRoute;
};

export const lettersPath = (patientId: number, letterId?: number) => {
  let resultRoute = `${patientsRoute}/${patientId}${letterRoute}`;

  if (letterId) {
    resultRoute = `${resultRoute}/${letterId}/edit`;
  }

  return resultRoute;
};

export const manualResultEntryPath = (patientId: number) => {
  return `${patientsRoute}/${patientId}${manualResultEntryRoute}`;
};

export const procedureInteractionPath = (
  patientId: number,
  procedureInteractionId: number,
) => {
  return `${patientsRoute}/${patientId}${procedureInteractionRoute}/${procedureInteractionId}`;
};
export const admissionPath = (
  patientId: number | string,
  admissionId: number | string,
) => {
  return `${patientsRoute}/${patientId}${admissionsRoute}/${admissionId}`;
};
