import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppState } from '@app/core/store/store/app-store.reducer';
import { SummaryChronicCareBillingPrograms } from '@app/features/summaries/shared/summaries.type';
import { cloneDeep, isNotNullOrUndefined } from '@app/utils';

import { getProgramVisitBySummary } from './../../store/program-visit.actions';
import { ProgramVisitSelectors } from './../../store/program-visit.selectors';
import { ProgramVisit } from '../../shared/program-visit.type';

@Component({
  selector: 'omg-program-visit',
  templateUrl: './program-visit.component.html',
  styleUrls: ['./program-visit.component.scss'],
})
export class ProgramVisitComponent implements OnInit, OnDestroy {
  @Input() summaryId: number;
  @Input() chronicCareBillingPrograms: SummaryChronicCareBillingPrograms[];

  loadingProgramVisit: Observable<boolean>;
  programVisit: ProgramVisit;
  private unsubscribe = new Subject<void>();

  constructor(
    private programVisitSelectors: ProgramVisitSelectors,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.store.dispatch(
      getProgramVisitBySummary({ summaryId: this.summaryId }),
    );

    this.programVisitSelectors
      .get()
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe(programVisit => {
        this.programVisit = cloneDeep(programVisit);
      });

    this.loadingProgramVisit = this.programVisitSelectors.loading(
      getProgramVisitBySummary,
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
