import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { initializeSentry } from '@app/core/errors/sentry';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

initializeSentry();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    if (err instanceof Error) {
      Sentry.captureException(err);
    } else if ('error' in err && err.error instanceof Error) {
      Sentry.captureException(err.error);
    } else if ('message' in err && err.message) {
      Sentry.captureException(err.message);
    }
    console.error(err);
  });
