import { Component, Input, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { get } from 'lodash';
import { filterTruthy } from '@app/utils';
import { switchMap } from 'rxjs/operators';
import { PatientSelectors } from '@app/core';
import {
  GetThreadSummaryQueryService,
  SummaryFragment,
} from '@app/modules/messaging/components/thread-summary/thread-summary.onelife.generated';
@Component({
  selector: 'omg-thread-summary',
  templateUrl: './thread-summary.html',
  styleUrls: ['./thread-summary.component.scss'],
})
export class ThreadSummaryComponent implements OnInit {
  summary$: Observable<SummaryFragment | null>;

  @Input() threadId: number;

  constructor(
    private threadSummaryService: GetThreadSummaryQueryService,
    private patientSelectors: PatientSelectors,
  ) {}

  ngOnInit() {
    this.summary$ = this.patientSelectors.patientId.pipe(
      filterTruthy(),
      switchMap(patientId =>
        this.threadSummaryService
          .fetch({
            id: patientId.toString(),
            ids: [this.threadId.toString()],
          })
      ),
      map(summaryResult => get(summaryResult, 'data.patient.posts.nodes[0].summary', null)),
    );
  }
}
