import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { S3Pointer } from '@app/modules/aws/shared/aws-session.type';

const MAX_TEXT_LENGTH = 300;

@Component({
  selector: 'omg-messaging-comment',
  templateUrl: './messaging-comment.component.html',
  styleUrls: ['./messaging-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagingCommentComponent {
  @Input() postedBy: any;
  @Input() text: string;
  @Input() updatedAt: string;
  @Input() s3Pointers: S3Pointer[];
  @Input() alwaysExpand: boolean;

  get textIsCollapsed() {
    return (
      !this.alwaysExpand && this.text && this.text.length > MAX_TEXT_LENGTH
    );
  }

  get parsedMarkdownText() {
    if (this.text) {
      // this is to fix an issue with unicode parsing of emails in angle brackets (#159667767)
      const unbracketed = this.text
        .replace(/\\</g, '&lt;')
        .replace(/\\>/g, '&gt;');
      return unbracketed;
    }
    return null;
  }
}
