import { AbstractControl, ValidationErrors } from '@angular/forms';

import { dateIsString, isDateBefore, parseISO, startOfDay } from '@app/utils';

export const futureDateValidator = (
  control: AbstractControl,
): ValidationErrors | null => {
  const todaysDate = startOfDay(new Date());

  const value = dateIsString(control.value)
    ? parseISO(control.value)
    : <Date>control.value;
  const dateIsBefore = isDateBefore(value, todaysDate);

  const error = {
    pastDate: true,
  };

  if (dateIsBefore === true) {
    return error;
  }

  return null;
};
