import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core/store/shared/fsa-action';

import { Comment } from '../shared/comments.type';
import { RenewalCommentsState } from './renewal-comments.reducer';

export enum RenewalCommentsActionTypes {
  GetRenewalComments = '[Comments] Get Renewal Comments',
  GetRenewalCommentsSuccess = '[Comments] Get Renewal Comments Success',
  GetRenewalCommentsError = '[Comments] Get Renewal Comments Error',
  CreateRenewalComment = '[Comments] Create Renewal Comments',
  CreateRenewalCommentSuccess = '[Comments] Create Renewal Comments Success',
  CreateRenewalCommentError = '[Comments] Create Renewal Comments Error',
}

type RenewalCommentsErrorData = any;

interface CreateRenewalCommentData {
  commentableId: number;
  commentBody: string;
}

export class GetRenewalComments implements FsaAction<number> {
  readonly type = RenewalCommentsActionTypes.GetRenewalComments;
  error = false;

  constructor(public payload: number) {}
}

export class GetRenewalCommentsSucccess implements FsaAction<Comment[]> {
  readonly type = RenewalCommentsActionTypes.GetRenewalCommentsSuccess;
  error = false;

  constructor(public payload: Comment[]) {}
}

export class GetRenewalCommentsError
  implements FsaAction<RenewalCommentsErrorData>
{
  readonly type = RenewalCommentsActionTypes.GetRenewalCommentsError;
  error = true;

  constructor(public payload: RenewalCommentsErrorData) {}
}

export class CreateRenewalComment
  implements FsaAction<CreateRenewalCommentData>
{
  readonly type = RenewalCommentsActionTypes.CreateRenewalComment;
  error = false;

  constructor(public payload: CreateRenewalCommentData) {}
}

export class CreateRenewalCommentSuccess implements FsaAction<Comment> {
  readonly type = RenewalCommentsActionTypes.CreateRenewalCommentSuccess;
  error = false;

  constructor(public payload: Comment) {}
}

export class CreateRenewalCommentError
  implements FsaAction<RenewalCommentsErrorData>
{
  readonly type = RenewalCommentsActionTypes.CreateRenewalCommentError;
  error = true;

  constructor(public payload: RenewalCommentsErrorData) {}
}

export type RenewalCommentsAction =
  | GetRenewalComments
  | GetRenewalCommentsSucccess
  | GetRenewalCommentsError
  | CreateRenewalComment
  | CreateRenewalCommentSuccess
  | CreateRenewalCommentError;

@Injectable()
export class RenewalCommentsActions {
  constructor(private store: Store<RenewalCommentsState>) {}

  getRenewalComments(id: number) {
    this.store.dispatch(new GetRenewalComments(id));
  }

  createRenewalComment(commentableId: number, commentBody: string = '') {
    this.store.dispatch(
      new CreateRenewalComment({ commentableId, commentBody }),
    );
  }
}
