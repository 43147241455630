// @ts-strict-ignore
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import { HistoricalProcedureCaptureForm } from '../../shared/historical-procedure-capture-form';
import {
  ProcedureCaptureFormValue,
  procedureTypeOptions,
} from '../../shared/historical-procedure-capture.type';
import { HistoricalProcedureCaptureActions } from '../../store/historical-procedure-capture.actions';
import CDPSnomedCodes, {
  SnomedCodeNode,
  SnomedCodeValues,
} from './cdp-snomed-codes';

@Component({
  selector: 'omg-procedure-capture-edit',
  templateUrl: './procedure-capture-edit.component.html',
  styleUrls: ['./procedure-capture-edit.component.scss'],
})
export class ProcedureCaptureEditComponent implements OnInit, OnChanges {
  @Input() documentId: number;
  @Input() form: HistoricalProcedureCaptureForm;
  @Input() isCaptureGenerated = false;
  @Input() isCaptureVerified = false;
  @Input() isCaptureLoading = true;
  @Output() collapseForm = new EventEmitter();

  procedureCdpSnomedCodesFF: boolean;
  currentDate = new Date();
  procedureTypeOptions = procedureTypeOptions;
  formAttemptedSubmit = false;
  procedureCdpSnomedCodesTreeNodes: any;
  selectedNode: SnomedCodeNode;

  constructor(
    private historicalProcedureCaptureActions: HistoricalProcedureCaptureActions,
    ld: LaunchDarklyService,
  ) {
    this.procedureCdpSnomedCodesFF = ld.variation(
      'procedure-cdp-snomed-codes',
      false,
    );
  }

  ngOnInit() {
    // initialize tree select menu data for procedures
    this.procedureCdpSnomedCodesTreeNodes = CDPSnomedCodes.expansion.contains.map(
      procedureList => {
        return processProcedureList(procedureList);
      },
    );

    function processProcedureList(procedureList) {
      // remove ' (procedure)' from end of all display names
      const procedureListDisplayName =
        procedureList.display.slice(-12) === ' (procedure)'
          ? procedureList.display.slice(0, -12)
          : procedureList.display;
      return {
        label: procedureListDisplayName,
        code: procedureList.code,
        children: procedureList.contains
          ? procedureList.contains.map(childList =>
              processProcedureList(childList),
            )
          : [],
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isCaptureGenerated) {
      changes.isCaptureGenerated.currentValue
        ? this.form.controls.enable()
        : this.form.controls.disable();
    }
  }

  get mlSuggestionText(): string {
    if (this.isCaptureLoading) {
      return 'Loading...';
    }

    if (!this.isCaptureGenerated) {
      return "1Life is scanning the document to extract data. You'll be able to enter procedure data once it's complete in a few minutes.";
    }

    if (!this.isCaptureVerified) {
      return 'Please review scanned data';
    }

    return undefined
  }

  saveHistoricalProcedureCapture(formValue: ProcedureCaptureFormValue): void {
    if (this.form.controls.valid && formValue.procedureFhirId) {
      this.historicalProcedureCaptureActions.verifyProcedure(
        formValue,
        this.documentId,
      );
    } else {
      this.formAttemptedSubmit = true;
      this.form.controls.markAllAsTouched();
    }
  }

  setProcedureCodeFormControlFromTreeSelect(code: string): void {
    this.form.controls.get('procedureCode').patchValue(code);
  }

  controlHasError(controlName: string): boolean {
    const control = this.form.controls.get(controlName);
    return control.invalid && this.formAttemptedSubmit;
  }
}
