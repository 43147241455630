import { Pipe, PipeTransform } from '@angular/core';

// Adapted from
// https://github.com/jonschlinkert/word-wrap
const wrap = (str, opts): string => {
  if (str === null) {
    return str;
  }

  const defaults = { width: 50, trim: true, cut: false };
  const options = { ...defaults, ...opts };
  const newline = '\n';

  let regexString = `.{1,${options.width}}`;

  if (options.cut !== true) {
    regexString += '([\\s\u200B]+|$)|[^\\s\u200B]+?([\\s\u200B]+|$)';
  }

  const re = new RegExp(regexString, 'g');
  const lines = str.match(re) || [];
  const result = lines
    .map(line => {
      if (line.slice(-1) === '\n') {
        line = line.slice(0, line.length - 1);
      }
      return line;
    })
    .join(newline);

  if (options.trim === true) {
    return result.trim();
  }
  return result;
};

@Pipe({
  name: 'omgWrapText',
})
export class WrapTextPipe implements PipeTransform {
  transform(text: string, width = 50, cut = false, trim = true): string {
    return wrap(text, { width, cut, trim });
  }
}
