import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DetectResizeService } from './detect-resize.service';

@Directive({
  selector: '[omgDetectResize]',
})
export class DetectResizeDirective implements OnInit, OnDestroy {
  @Output() resizeEvent = new EventEmitter<DOMRectReadOnly>();
  private unsubscribe$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private detectResizeService: DetectResizeService,
  ) {}

  ngOnInit() {
    this.detectResizeService
      .getResizeEvents$(this.el)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(contentRect =>
        this.resizeEvent.emit(contentRect ?? undefined),
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
