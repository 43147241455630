import { Component, Input } from '@angular/core';

import { DocumentSummary } from '../../../../../graphql/onelife.type';

@Component({
  selector: 'omg-document-summary-timestamp',
  templateUrl: './document-summary-timestamp.component.html',
  styleUrls: ['./document-summary-timestamp.component.scss'],
})
export class DocumentSummaryTimestampComponent {
  @Input()
  summary: DocumentSummary;
}
