import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import * as Sentry from '@sentry/angular-ivy';
import { Client } from 'elasticsearch-browser';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { ConfigService } from '@app/core/config';
import { camelCase } from '@app/utils';

interface SearchOptions {
  camelize?: boolean;
}

/**
 * @deprecated - Use OpenSearchDataFetcher instead
 */
@Injectable()
export class SearchService {
  private client$ = new ReplaySubject<Client>(1);
  private initialized = false;

  constructor(
    private configService: ConfigService,
    private auth: AuthService,
  ) {}

  search(request: any, options: SearchOptions = {}): Observable<any> {
    this.initialize();
    return this.searchRequest(request, options, 'search');
  }

  private searchRequest(
    request: any,
    options: SearchOptions = {},
    mode: 'search' | 'scroll',
  ) {
    return this.client$.pipe(
      switchMap(client => {
        if (!client) {
          throw new Error('Elasticsearch location is undefined');
        }
        const req =
          mode === 'scroll' ? client.scroll(request) : client.search(request);

        return req.then(response => {
          this.inspectForResponseFailures(response);
          return options.camelize ? camelCase(response) : response;
        });
      }),
    );
  }

  private inspectForResponseFailures(response: any): void {
    // inspect ES client response and log if there are any failed shards
    // https://opensearch.org/docs/1.3/api-reference/search/#response-body
    // https://www.elastic.co/guide/en/elasticsearch/reference/current/docs-index_.html#docs-index-api-response-body
    // we don't check for `response._shards.skipped` because the default behaviour is to fail if any shards in a cluster fail.
    // shards are only skipped if the query explicitly sets `skip_unavailable` = true
    const failed = response?._shards?.failed > 0;
    if (failed) {
      Sentry.captureException(
        `OpenSearch Result contains Failures: ${response?._shards?.failures}`,
      );
    }
  }

  private initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this.setupSearchClient();
  }

  private setupSearchClient() {
    this.auth
      .getAccessTokenSilently()
      .pipe(
        take(1),
        tap((accessTokenValue: string) => {
          this.useAuth0JwtAccess(accessTokenValue);
        }),
      )
      .subscribe();
  }

  private useAuth0JwtAccess(accessTokenValue) {
    const headers = {
      Authorization: `Bearer ${accessTokenValue}`,
    };

    const esUrl = new URL(this.configService.environment.search.proxyHost);
    const port = esUrl.port || '443';

    const elasticsearchClient = new Client({
      host: {
        host: esUrl.hostname,
        protocol: esUrl.protocol,
        port: port,
        path: esUrl.pathname,
        headers,
      },
    });
    this.client$.next(elasticsearchClient);
  }
}
