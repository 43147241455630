import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StateSelector } from '@app/core/store/shared/state';

import { CancelPrescriber } from '../../../modules/shared-rx/prescriber-credential.type';
import { PrescriptionHistory } from '../shared/prescription-history.type';

export class PrescriptionHistorySelectors extends StateSelector<PrescriptionHistory> {
  getCancelPrescribers(prescriptionId: number): Observable<CancelPrescriber[]> {
    return this.getById(prescriptionId).pipe(
      map(prescription => prescription?.cancelPrescribers || []),
    );
  }

  getCredentialsLoading(prescriptionId: number): Observable<boolean> {
    return this.getCancelPrescribers(prescriptionId).pipe(
      map(cancelPrescribers => !cancelPrescribers?.length),
    );
  }
}
