import {
  Directive,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
} from '@angular/core';
import { environment } from '@environments/environment';

import { windowToken } from '@app/shared/window/window.service';

export const openLegacyUrl = (
  path: string,
  window: Window,
  target?: string,
) => {
  const legacyUrl = `${environment.legacyUi.host}${path}`;
  window.open(legacyUrl, target || '_self');
};

@Directive({
  selector: '[omgLegacyUiHref], [omgNewHref]',
})
export class LegacyUiHrefDirective implements OnChanges {
  @HostBinding('attr.href') hrefAttr: string | null = null;
  @Input() omgLegacyUiHref: string;
  @Input() omgNewHref: string;
  @Input() target: string;
  @Input() useHref: boolean;

  constructor(@Inject(windowToken) private window: Window) {}

  ngOnChanges() {
    if (this.useHref) {
      this.hrefAttr = `${environment.legacyUi.host}${this.omgLegacyUiHref}`;
    }
    if (this.omgNewHref) {
      this.hrefAttr = `${this.omgNewHref}`;
    }
  }

  @HostListener('click')
  onClick() {
    if (!this.useHref) {
      openLegacyUrl(`${this.omgLegacyUiHref}`, this.window, this.target);
    }
  }
}
