import { Pipe, PipeTransform } from '@angular/core';

import { addDays, formatDateToDistance } from '@app/utils';

@Pipe({
  name: 'omgDaysToDistance',
})
export class DaysToDistancePipe implements PipeTransform {
  transform(numberOfDays: number): string {
    const date = addDays(Date.now(), numberOfDays);
    return formatDateToDistance(date);
  }
}
