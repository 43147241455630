import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, race, zip } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

import {
  ToastMessage,
  ToastMessageService,
} from '@app/shared/components/toast';

export interface ErrorStateMessage {
  errorState: Observable<any>;
  message?: Partial<ToastMessage>;
}

export interface StateValidatorOptions {
  loadFn: () => void;
  selectors: Observable<any>[];
  possibleErrorStates: ErrorStateMessage[];
  defaultErrorMessage?: Partial<ToastMessage>;
}

@Injectable()
export class StateValidator {
  constructor(
    private toastService: ToastMessageService,
    private router: Router,
  ) {}

  canActivate(options: StateValidatorOptions, fallbackUrl: string) {
    options.loadFn();
    return race<any>(zip(...options.selectors), ...this.mapToSelectors(options))
      .pipe(
        take(1),
        map(() => true),
        catchError(() => of(false)),
      )
      .pipe(
        tap(canActivate => {
          if (!canActivate) {
            this.router.navigateByUrl(fallbackUrl);
          }
        }),
      );
  }

  private mapToSelectors(options: StateValidatorOptions) {
    return options.possibleErrorStates.map(errorStateMessage => {
      return errorStateMessage.errorState.pipe(
        filter(error => !!error),
        tap(() => {
          if (options.defaultErrorMessage || errorStateMessage.message) {
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              sticky: true,
              ...options.defaultErrorMessage,
              ...errorStateMessage.message,
            });
          }

          throw new Error('Invalid state');
        }),
      );
    });
  }

  getPatientId(route: ActivatedRouteSnapshot) {
    const resolvedPatientRouteSnapshot = route.pathFromRoot.find(
      r => r.routeConfig && r.routeConfig.path === 'patients/:id/chart',
    );
    return resolvedPatientRouteSnapshot
      ? resolvedPatientRouteSnapshot.params.id
      : null;
  }
}
