import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { httpStatus } from '@app/utils';

import { logoutPath } from '../auth';

@Injectable()
export class UnauthorizedInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.handleUnauthorizedError(request, error),
        ),
      );
  }

  private handleUnauthorizedError(
    request: HttpRequest<any>,
    error: HttpErrorResponse,
  ) {
    const ignore401 = request.params.has('ignore401');

    if (error.status === httpStatus.UNAUTHORIZED && !ignore401) {
      this.router.navigateByUrl(`/${logoutPath}`);
    }

    return throwError(error);
  }
}
