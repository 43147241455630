import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Renderer2,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PrimeNGConfig } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: '[omgTooltip]',
})
export class TooltipDirective extends Tooltip implements OnInit, OnChanges {
  @Input()
  public tooltipText: string;

  @Input()
  public showTooltip = true;

  constructor(
    el: ElementRef,
    zone: NgZone,
    private sanitizer: DomSanitizer,
    private primengConfig: PrimeNGConfig,
    renderer: Renderer2,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(el, zone, primengConfig, renderer, changeDetectorRef);

    this.setOption({ showDelay: 250 });
  }

  public ngOnInit(): void {
    this.setOption({ disabled: !this.showTooltip });
    this.setSanitizedText(this.tooltipText);
    this.primengConfig.ripple = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.showTooltip) {
      this.setOption({ disabled: !changes.showTooltip.currentValue });
    }

    if (changes.tooltipText) {
      this.setSanitizedText(changes.tooltipText.currentValue);
    }
  }

  private setSanitizedText(rawText: string): void {
    const safeHTML = this.sanitizer.sanitize(SecurityContext.HTML, rawText);

    this.setOption({ tooltipLabel: <string>safeHTML });
  }
}
