import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Renewal } from '@app/features/renewals/shared/renewals.type';
import { PendingNewRx } from '@app/modules/rx-cart';

@Component({
  selector: 'omg-cart-item-name',
  templateUrl: './cart-item-name.component.html',
  styleUrls: ['./cart-item-name.component.scss'],
})
export class CartItemNameComponent {
  @Input() medication: PendingNewRx | Renewal;
  @Input() editable = false;
  @Input() deletable = false;
  @Input() availabilityStatus: string | null = '';
  @Input() name: string;
  @Input() heading: boolean;
  @Input() deaSchedule: number;
  @Input() forceEdit: boolean;
  @Input() description: string;
  @Input() instructions: string;
  @Input() loading$: Observable<boolean>;

  @Output() delete = new EventEmitter<number>();
  @Output() expanded = new EventEmitter<boolean>();
  @Output() regimenUpdate = new EventEmitter<any>();

  instructionsTooLongMessage =
    'These instructions must be edited to fewer than 140 characters before the renewal can be processed';

  onDelete() {
    this.loading$
      .pipe(
        filter(isLoading => !isLoading),
        take(1),
      )
      .subscribe(() => this.delete.emit());
  }

  onEditorExpand(expanded: boolean) {
    this.expanded.emit(expanded);
  }

  get availabilityStatusLabel() {
    if (this.availabilityStatus === 'currently_unavailable') {
      return 'Possible Delay';
    } else if (this.availabilityStatus === 'does_not_fill') {
      return 'Does Not Fill';
    }
    return null;
  }
}
