import { select } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { StateSelector } from '@app/core/store/shared/state';
import { isNotNullOrUndefined } from '@app/utils';

import { ChangeRx } from '../shared/change-rx.type';
import { ChangeRxState } from './change-rx.reducer';

export class ChangeRxSelectors extends StateSelector<ChangeRx> {
  get changeRxsCount() {
    return this.store.pipe(
      select(this.selectEntities),
      map(entities => entities.length),
    );
  }

  get changeRxSubmitAction() {
    return this.store.pipe(
      select(this.selectState),
      map((state: ChangeRxState) => state.changeRxSubmitAction),
      isNotNullOrUndefined(),
    );
  }
}
