import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { ChangeRx } from '@app/features/change-rx/shared/change-rx.type';
import { ChangeRxActions } from '@app/features/change-rx/store/change-rx.actions';
import { ChangeRxSubmitAction } from '@app/features/change-rx/store/change-rx.reducer';
import { ChangeRxSelectors } from '@app/features/change-rx/store/change-rx.selectors';

import { Renewal } from '../../shared/renewals.type';
import { RenewalActions, RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-renewals',
  templateUrl: './renewals.component.html',
  styleUrls: ['./renewals.component.scss'],
})
export class RenewalsComponent implements OnInit {
  changeRxEnabled$: Observable<boolean>;

  renewals$: Observable<Renewal[]>;
  changeRxs$: Observable<ChangeRx[]>;

  noRenewals$: Observable<boolean>;
  noChangeRxs$: Observable<boolean>;

  loading$: Observable<boolean>;
  empty$: Observable<boolean>;
  cartComplete$: Observable<boolean>;
  changeRxSubmitAction$: Observable<ChangeRxSubmitAction>;

  constructor(
    private renewalActions: RenewalActions,
    private renewalSelectors: RenewalSelectors,
    private changeRxActions: ChangeRxActions,
    private changeRxSelectors: ChangeRxSelectors,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.renewalActions.loadRenewalCart();
    this.renewals$ = this.renewalSelectors.entities;
    this.changeRxs$ = this.changeRxSelectors.entities;

    this.changeRxEnabled$ = this.launchDarklyService.variation$(
      FeatureFlagNames.medsChangeRx,
      false,
    );

    this.changeRxEnabled$
      .pipe(
        filter(Boolean),
        tap(() => this.changeRxActions.load()),
        take(1),
      )
      .subscribe();

    this.noRenewals$ = this.renewals$.pipe(
      map(renewals => renewals.length === 0),
    );

    this.noChangeRxs$ = this.changeRxEnabled$.pipe(
      switchMap(enabled =>
        enabled
          ? this.changeRxs$.pipe(map(changeRxs => changeRxs.length === 0))
          : of(true),
      ),
    );

    this.empty$ = combineLatest([this.noRenewals$, this.noChangeRxs$]).pipe(
      map(([noRenewals, noChangeRx]) => noRenewals && noChangeRx),
    );

    this.cartComplete$ = this.renewalSelectors.cartComplete;

    this.changeRxSubmitAction$ = this.changeRxEnabled$.pipe(
      filter(Boolean),
      switchMap(() => this.changeRxSelectors.changeRxSubmitAction),
      take(1),
    );

    this.loading$ = combineLatest([
      this.empty$,
      this.renewalSelectors.loading,
      this.changeRxSelectors.loading,
    ]).pipe(
      map(
        ([empty, loadingRenewals, loadingChangeRxs]) =>
          empty && (loadingRenewals || loadingChangeRxs),
      ),
    );
  }
}
