import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { isValidFuzzyDate } from '@app/utils';

export const fuzzyDateValidator = (
  control: AbstractControl,
): ValidationErrors | null => {
  const date = control.value;
  const error = {
    dateFuzzy:
      'Date must be MM/DD/YYYY, MM/YYYY or YYYY. Month is from 01 to 12.',
  };

  if (date === '') {
    return null;
  }

  if (!control.pristine) {
    if (!isValidFuzzyDate(date)) {
      return error;
    }

    const dateArr = date.split('/');

    if (dateArr.length > 1) {
      const month = parseInt(dateArr[0], 10);
      return !!month && month > 0 && month < 13 ? null : error;
    }
  }

  return null;
};

export const asyncFuzzyDateValidator =
  (delay: number): AsyncValidatorFn =>
  (control: AbstractControl): Observable<ValidationErrors | null> =>
    timer(delay).pipe(switchMap(() => of(fuzzyDateValidator(control))));
