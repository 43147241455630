import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core/api';

import {
  mapPatientGetResponseToEntity,
  mapToPcpCommentsUpdateRequest,
} from './patient-api-mappers';
import { PatientResponse } from './patient-response.type';
import {
  PcpCommentsUpdateData,
  PcpCommentsUpdateResponse,
} from './pcp-comments-update.type';

const PATIENT_PATH = '/v2/admin/patients';

export const patientRoute = (patientId: number, subRoute: string = '') =>
  `${PATIENT_PATH}/${patientId}${subRoute}`;

const createPatientInfoRoute = (patientId: number) => patientRoute(patientId);

@Injectable()
export class PcpCommentsApiService {
  constructor(private api: ApiService) {}

  get(patientId) {
    return this.api
      .get<PatientResponse>(createPatientInfoRoute(patientId))
      .pipe(map(mapPatientGetResponseToEntity));
  }

  update(patientId, data: PcpCommentsUpdateData) {
    return this.api
      .update<PcpCommentsUpdateResponse>(
        createPatientInfoRoute(patientId),
        mapToPcpCommentsUpdateRequest(data),
      )
      .pipe(map(mapPatientGetResponseToEntity));
  }
}
