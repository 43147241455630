import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { NoteApiService } from '@app/modules/note/shared/note-api.service';
import { DialogRef, OMG_DIALOG_DATA } from '@app/shared/components/dialog';
import { ToastMessageService } from '@app/shared/components/toast';
import { phoneNumberValidator } from '@app/shared/forms/validators/phone-number-validator';

interface DialogData {
  noteId: number;
}

@Component({
  selector: 'omg-fax-document-form',
  templateUrl: './fax-document-form.component.html',
  styleUrls: ['./fax-document-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaxDocumentFormComponent implements OnInit {
  form: UntypedFormGroup;
  faxNumberControlError = 'Fax number is invalid.';
  formSubmitted = false;

  private faxQueued = 'Your document has been added to the outbound fax queue.';

  constructor(
    public dialogRef: DialogRef<FaxDocumentFormComponent>,
    @Inject(OMG_DIALOG_DATA) public data: DialogData,
    private formBuilder: UntypedFormBuilder,
    private noteApiService: NoteApiService,
    private toastService: ToastMessageService,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  onClose() {
    this.dialogRef.close();
    this.markSubmitted(false);
  }

  fax() {
    this.markSubmitted(true);

    return this.noteApiService
      .fax(this.data.noteId, this.form.value)
      .subscribe(() => {
        this.onClose();
        this.markSubmitted(false);
        this.toastService.add({ severity: 'success', detail: this.faxQueued });
      });
  }

  markSubmitted(submitted: boolean) {
    this.formSubmitted = submitted;
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      faxNumber: new UntypedFormControl(null, [
        phoneNumberValidator(),
        Validators.required,
      ]),
      coverPageText: new UntypedFormControl(''),
    });
  }
}
