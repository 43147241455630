/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type AppointmentRecordingFragment = { __typename?: 'AppointmentRecording', state: Types.AppointmentRecordingState, id: string };

export type GetAppointmentRecordingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetAppointmentRecordingQuery = { __typename?: 'Query', appointment?: { __typename?: 'Appointment', recording?: { __typename?: 'AppointmentRecording', state: Types.AppointmentRecordingState, id: string } | null } | null };

export const AppointmentRecordingFragmentDoc = gql`
    fragment AppointmentRecording on AppointmentRecording {
  state
  id
}
    `;
export const GetAppointmentRecordingDocument = gql`
    query GetAppointmentRecording($id: ID!) {
  appointment(id: $id) {
    recording {
      ...AppointmentRecording
    }
  }
}
    ${AppointmentRecordingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAppointmentRecordingQueryService extends Query<GetAppointmentRecordingQuery, GetAppointmentRecordingQueryVariables> {
    document = GetAppointmentRecordingDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }