import { AbstractControl, ValidationErrors } from '@angular/forms';

import { isDateAfter, isValidFuzzyDate, parseFuzzyDate } from '@app/utils';

export const pastFuzzyDateDateValidator = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!isValidFuzzyDate(control.value)) {
    return null;
  }

  const todaysDate = new Date();
  const { date: enteredDate } = parseFuzzyDate(control.value);
  const dateIsAfterToday = isDateAfter(enteredDate, todaysDate);

  if (dateIsAfterToday) {
    return {
      pastFuzzyDate: 'Date cannot be set in the future.',
    };
  }

  return null;
};
