// @ts-strict-ignore
import { Injectable } from '@angular/core';
import {
  initialize,
  LDClient,
  LDEvaluationDetail,
  LDOptions,
  LDSingleKindContext,
} from 'launchdarkly-js-client-sdk';
import { Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { ConfigService } from '@app/core/config/config.service';
import { Profile, ProfileSelectors } from '@app/core/profile';

@Injectable()
export class LaunchDarklyService {
  ldclient: LDClient;
  readonly initialized$ = new ReplaySubject<boolean>(1);

  constructor(
    private configService: ConfigService,
    private profileSelectors: ProfileSelectors,
  ) {}

  /**
   * Returns an observable that emits true when the sdk is initialized.
   */
  init(): Observable<boolean> {
    // initializing the launchdarkly sdk is an asynchronous operation.
    // it makes an api call to launchdarkly cloud and once its ready, it emits
    // the 'ready' event indicating methods can be invoked on it.
    // https://docs.launchdarkly.com/sdk/client-side/javascript#code-sample
    const config = this.configService.environment.launchdarkly;
    if (config && config.clientSideId) {
      this.profileSelectors.profile
        .pipe(first(profile => (profile ? true : false)))
        .subscribe(profile => {
          const context = this.buildContext(profile);
          const options: LDOptions = {
            evaluationReasons: true,
          };
          this.ldclient = initialize(config.clientSideId, context, options);
          this.ldclient.on('ready', () => {
            this.initialized$.next(true);
            this.initialized$.complete();
          });
        });
      return this.initialized$;
    } else {
      throw new Error(
        'LaunchDarkly client side id is required to initialize the LaunchDarkly sdk. Make sure the environment config contains `launchdarkly.clientSideId`',
      );
    }
  }

  /**
   * Guarantees that launchdarkly is initialized before emitting flag value.
   * This is mostly useful for code which runs before the LaunchDarklyResolver
   * (ie in Angular modules and other early initialization code).
   */
  variation$<T>(flag: string, defaultValue: T): Observable<T> {
    return this.initialized$.pipe(
      first(initialized => initialized),
      map(() => this.ldclient.variation(flag, defaultValue)),
    );
  }

  /**
   * Returns a feature flag variation.
   *
   * In the client-side JavaScript SDKs, the underlying `ldclient.variation()` method is always a fast,
   * synchronous operation because all of the feature flag values for the current user
   * have already been loaded into memory.
   *
   * This synchronous method can be used by chart ui components since they only render after
   * the LaunchDarklyResolver ensures that the sdk is initialized.
   *
   * @param flag the name of the feature flag
   * @param defaultValue default value to use if the feature flag is not defined in launch darkly
   * @returns the feature flag value, or if undefined, the default value
   */
  variation<T>(flag: string, defaultValue: T): T {
    return this.ldclient.variation(flag, defaultValue);
  }

  variationDetail<T>(flag: string, defaultValue?: T): LDEvaluationDetail {
    return this.ldclient.variationDetail(flag, defaultValue);
  }

  track(eventName: string): void {
    this.ldclient.track(eventName);
  }

  private buildContext(profile: Profile): LDSingleKindContext {
    const email = profile.identities.find(identity => identity.primary).email;
    const context: LDSingleKindContext = {
      kind: 'user',
      key: profile.id.toString(),
      firstName: profile.firstName,
      lastName: profile.lastName,
      email,
      anonymous: false,
      roles: profile.roles,
      serviceAreaId: profile.serviceAreaId,
    };
    return context;
  }
}
