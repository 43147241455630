import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';

import { PatientPharmacy } from '../shared/patient-pharmacy.type';
import { PatientPharmacyState } from './patient-pharmacy.reducer';

export enum PatientPharmacyTypes {
  LOAD_PATIENT_PHARMACIES = '[Patient Pharmacy] Load Pharmacies',
  LOAD_PATIENT_PHARMACIES_SUCCESS = '[Patient Pharmacy] Load Pharmacies Success',
  LOAD_PATIENT_PHARMACIES_ERROR = '[Patient Pharmacy] Load Pharmacies Error',
  CREATE_PATIENT_PHARMACY = '[Patient Pharmacy] Create Pharmacies',
  CREATE_PATIENT_PHARMACY_SUCCESS = '[Patient Pharmacy] Create Pharmacies Success',
  CREATE_PATIENT_PHARMACY_ERROR = '[Patient Pharmacy] Create Pharmacies Error',
  DELETE_PATIENT_PHARMACY = '[Patient Pharmacy] Delete Pharmacies',
  DELETE_PATIENT_PHARMACY_SUCCESS = '[Patient Pharmacy] Delete Pharmacies Success',
  DELETE_PATIENT_PHARMACY_ERROR = '[Patient Pharmacy] Delete Pharmacies Error',
  SET_SELECTED_PATIENT_PHARMACY = '[Patient Pharmacy] Set Selected Pharmacy',
  RESET_PREFERRED_PATIENT_PHARMACIES = '[Patient Pharmacy] Reset Preferred Pharmacies',
}

type PharmacyErrorData = any;
type PatientPharmacyId = number;

export class LoadPatientPharmacies implements FsaAction<void> {
  readonly type = PatientPharmacyTypes.LOAD_PATIENT_PHARMACIES;
}

export class LoadPatientPharmaciesSuccess
  implements FsaAction<PatientPharmacy[]>
{
  readonly type = PatientPharmacyTypes.LOAD_PATIENT_PHARMACIES_SUCCESS;

  constructor(public payload: PatientPharmacy[]) {}
}

export class LoadPatientPharmaciesError
  implements FsaAction<PharmacyErrorData>
{
  readonly type = PatientPharmacyTypes.LOAD_PATIENT_PHARMACIES_ERROR;

  constructor(public payload: PharmacyErrorData) {}
}

export class CreatePatientPharmacy implements FsaAction<PatientPharmacy> {
  readonly type = PatientPharmacyTypes.CREATE_PATIENT_PHARMACY;

  constructor(public payload: PatientPharmacy) {}
}

export class CreatePatientPharmacySuccess
  implements FsaAction<PatientPharmacy>
{
  readonly type = PatientPharmacyTypes.CREATE_PATIENT_PHARMACY_SUCCESS;

  constructor(public payload: PatientPharmacy) {}
}

export class CreatePatientPharmacyError
  implements FsaAction<PharmacyErrorData>
{
  readonly type = PatientPharmacyTypes.CREATE_PATIENT_PHARMACY_ERROR;

  constructor(public payload: PharmacyErrorData) {}
}

export class DeletePatientPharmacy implements FsaAction<PatientPharmacyId> {
  readonly type = PatientPharmacyTypes.DELETE_PATIENT_PHARMACY;

  constructor(public payload: PatientPharmacyId) {}
}

export class DeletePatientPharmacySuccess
  implements FsaAction<PatientPharmacyId>
{
  readonly type = PatientPharmacyTypes.DELETE_PATIENT_PHARMACY_SUCCESS;

  constructor(public payload: PatientPharmacyId) {}
}

export class DeletePatientPharmacyError
  implements FsaAction<PharmacyErrorData>
{
  readonly type = PatientPharmacyTypes.DELETE_PATIENT_PHARMACY_ERROR;

  constructor(public payload: PharmacyErrorData) {}
}

export class SetSelectedPatientPharmacy implements FsaAction<number> {
  readonly type = PatientPharmacyTypes.SET_SELECTED_PATIENT_PHARMACY;

  constructor(public payload: number) {}
}

export class ResetPreferredPatientPharmacies
  implements FsaAction<PatientPharmacy[]>
{
  readonly type = PatientPharmacyTypes.RESET_PREFERRED_PATIENT_PHARMACIES;

  constructor(public payload: PatientPharmacy[]) {}
}

export type PatientPharmacyAction =
  | LoadPatientPharmacies
  | LoadPatientPharmaciesSuccess
  | LoadPatientPharmaciesError
  | CreatePatientPharmacy
  | CreatePatientPharmacySuccess
  | CreatePatientPharmacyError
  | DeletePatientPharmacy
  | DeletePatientPharmacySuccess
  | DeletePatientPharmacyError
  | SetSelectedPatientPharmacy
  | ResetPreferredPatientPharmacies;

@Injectable({
  providedIn: 'root',
})
export class PatientPharmacyActions {
  constructor(private store: Store<PatientPharmacyState>) {}

  loadPatientPharmacies() {
    this.store.dispatch(new LoadPatientPharmacies());
  }

  createPatientPharmacy(pharmacy: PatientPharmacy) {
    this.store.dispatch(new CreatePatientPharmacy(pharmacy));
  }

  deletePatientPharmacy(patientPharmacyId: number) {
    this.store.dispatch(new DeletePatientPharmacy(patientPharmacyId));
  }

  setSelectedPatientPharmacy(patientPharmacyId: number) {
    this.store.dispatch(new SetSelectedPatientPharmacy(patientPharmacyId));
  }
}
