import { Observable } from 'rxjs';

import { StateSelector } from '@app/core/store/shared/state';

import { ProcedureSuggestion } from '../shared/procedure-suggestion.type';

export class ProcedureSuggestionSelectors extends StateSelector<ProcedureSuggestion> {
  getBySummaryId(summaryId: number): Observable<ProcedureSuggestion[]> {
    return this.filter(
      procedureSuggestion => procedureSuggestion.summaryId === summaryId,
    );
  }
}
