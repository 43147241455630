import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PatientSelectors, Profile, ProfileSelectors } from '@app/core';
import {
  CheckoutBlocked,
  PDMPService,
} from '@app/modules/shared-rx/services/pdmp.service';

import { CheckoutRenewal } from '../../shared/renewals.type';
import { RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-ready-for-checkout',
  templateUrl: './ready-for-checkout.component.html',
  styleUrls: ['./ready-for-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PDMPService,
    { provide: 'CartDetailsProvider', useExisting: RenewalSelectors },
  ],
})
export class ReadyForCheckoutComponent implements OnInit {
  approvedOrDeniedRenewals$: Observable<CheckoutRenewal[]>;
  acceptsDigitalCommunications$: Observable<boolean>;
  cartId$: Observable<number>;
  profile$: Observable<Profile>;
  isProvider$: Observable<boolean>;
  loading$: Observable<boolean>;
  incompleteDemographics$: Observable<boolean>;
  checkoutAttempted: boolean;
  checkoutPrevented$: Observable<CheckoutBlocked>;
  showPdmpDialogueBanner$: Observable<boolean>;

  @Input()
  isListView = false;

  constructor(
    private renewalSelectors: RenewalSelectors,
    private patientSelectors: PatientSelectors,
    private profileSelectors: ProfileSelectors,
    private PDMPService: PDMPService,
  ) {}

  ngOnInit() {
    this.acceptsDigitalCommunications$ =
      this.patientSelectors.acceptsDigitalCommunications;
    this.approvedOrDeniedRenewals$ =
      this.renewalSelectors.approvedOrDeniedRenewals.pipe(
        map(renewals =>
          renewals.map(renewal => {
            const handwrittenConfirmed = !renewal.mustHandwrite;

            return {
              ...renewal,
              handwrittenConfirmed,
            };
          }),
        ),
      );

    this.cartId$ = this.renewalSelectors.cartId;
    this.isProvider$ = this.profileSelectors.hasRole('provider');
    this.loading$ = this.renewalSelectors.loading;
    this.profile$ = this.profileSelectors.profile;
    this.incompleteDemographics$ =
      this.patientSelectors.hasIncompleteDemographics;

    this.showPdmpDialogueBanner$ = this.PDMPService.readyToSignRequired$;
    this.checkoutPrevented$ = this.PDMPService.checkoutDialogBlocked$;
  }
}
