import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isDateBefore, startOfMonth, startOfYear } from '@app/utils';
import {
  FuzzyDate,
  isValidFuzzyDate,
  parseFuzzyDate,
} from '@app/utils/dates/fuzzy-date-utils';

const beforeBirthDate = (
  enteredDate: number | Date,
  laterDate: number | Date,
) => isDateBefore(enteredDate, laterDate);

const dateAfterBirthDate = (fuzzyDate: FuzzyDate, birthDate: number | Date) => {
  const { date: enteredDate, precision } = fuzzyDate;

  let comparisonDate;
  switch (precision) {
    case 'year':
      comparisonDate = startOfYear(birthDate);
      break;
    case 'month':
      comparisonDate = startOfMonth(birthDate);
      break;
    default:
      comparisonDate = birthDate;
      break;
  }
  return beforeBirthDate(enteredDate, comparisonDate);
};

export const fuzzyDateAfterBirthDateValidator = (
  birthDate: Date,
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isValidFuzzyDate(control.value)) {
      return null;
    }

    if (dateAfterBirthDate(parseFuzzyDate(control.value), birthDate)) {
      return {
        fuzzyDateBeforeBirthDate: "Date cannot be before patient's birth date.",
      };
    }
    return null;
  };
};
