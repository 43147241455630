import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core';

import { mapNoteAttachmentResponseToEntity } from './note-api-mappers';
import { NoteAttachmentResponse } from './note-api.type';

interface AttachmentSaveData {
  attachable_type: string;
  attachable_id: number;
  file: File;
}

@Injectable({
  providedIn: 'root',
})
export class NoteAttachmentService {
  readonly attachmentPath = '/v2/admin/attachments';

  constructor(private apiService: ApiService) {}

  save(params: AttachmentSaveData) {
    const formData = new FormData();
    for (const key of Object.keys(params)) {
      formData.append(key, params[key]);
    }

    return this.apiService
      .save<NoteAttachmentResponse>(this.attachmentPath, formData)
      .pipe(map(mapNoteAttachmentResponseToEntity));
  }

  delete(id: number) {
    return this.apiService.delete(`${this.attachmentPath}/${id}`);
  }
}
