<div class="om-chart-text-box">
  <quill-editor #editor
                class="editor"
                data-cy="quill-editor"
                [readOnly]="readOnly"
                [modules]="modules"
                [format]="format"
                [formats]="formats"
                [scrollingContainer]="scrollingContainer"
                [placeholder]="placeholder"
                [formControl]="control"
                [singleLine]="singleLine"
                [classes]="classes"
                [attr.data-cy]="dataCy"
                [maxLength]="maxLength"
                (onEditorCreated)="editorCreated($event)"
                (onFocus)="editorFocused()"
                (focusout)="editorFocusedOut.emit()"
                (onEditorCreated)="editorInitialized.emit($event)"
                (onContentChanged)="contentChanged.emit($event)"
                omgInsertionTriggers
                [insertionEventProps]="insertionEventProps"
                [insertionQuill]="quillInstance"
                [templateType]="templateType"
                [selectedTemplate]="selectedTemplate"
                [insertionState]="insertionState$ | ngrxPush"
                [isInlineInsertionActive]="isInlineInsertionActive$ | ngrxPush"
                [isQuillInFocus]="isQuillInFocus"
                [trimOnValidation]="true"
                [required]="required">
    <div quill-editor-toolbar
         *ngIf="format === 'html'">
      <div om-layout
           om-layout-fill
           om-layout-align="space-between center">
        <span class="ql-formats">
        <button class="ql-bold"
                title="Bold"
                tabindex="-1"></button>
        <button class="ql-italic"
                title="Italic"
                tabindex="-1"></button>
        <button class="ql-link"
                title="Link"
                tabindex="-1"></button>
        <button class="ql-list"
                value="ordered"
                title="Ordered List"
                tabindex="-1"></button>
        <button class="ql-list"
                value="bullet"
                title="Bullet List"
                tabindex="-1"></button>
          </span>
        <ng-container [ngTemplateOutlet]="customButtons"></ng-container>
      </div>
    </div>
  </quill-editor>
  <omg-inline-insertion #insertion
                        [ngStyle]="insertionPositionStyle$ | ngrxPush"
                        [insertionState$]="insertionState$"
                        [previewAlignment]="insertionPreviewAlignment$ | ngrxPush"
                        [searchIndex]="activeSearchIndex$ | ngrxPush"
                        (itemSelect)="itemSelected($event)"
                        *ngIf="isInlineInsertionActive$ | ngrxPush"></omg-inline-insertion>
  <omg-insertion-indicator [isActive]="isInlineInsertionActive$ | ngrxPush"
                           *ngIf="isQuillInFocus"></omg-insertion-indicator>
</div>
