import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { S3Service } from '@app/modules/aws/shared/s3.service';
import { filterTruthy } from '@app/utils';

import { getObjectPdfUrl } from '../../shared/s3-utils';

@Component({
  selector: 'omg-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input() key: string;
  @Input() bucket: string;
  safeUrl$: Observable<SafeResourceUrl>;

  constructor(private s3Service: S3Service, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.safeUrl$ = this.s3Service.getObject(this.key, this.bucket).pipe(
      filterTruthy(),
      map(getObjectPdfUrl),
      map(url =>
        this.sanitizer.bypassSecurityTrustResourceUrl(url + '#navpanes=0'),
      ),
    );
  }
}
