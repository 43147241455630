import { createAction, props } from '@ngrx/store';

import {
  ProgramEnrollment,
  ProgramVisit,
} from '@app/features/program-visit/shared/program-visit.type';

import { ProgramCarePlan } from './../shared/program-visit.type';

export const getProgramVisitBySummary = createAction(
  '[Program Visit] Get Program Visit By Summary',
  props<{ summaryId: number }>(),
);

export const getProgramVisitBySummarySuccess = createAction(
  '[Program Visit] Get Program Visit By Summary Success',
  props<{ programVisit: ProgramVisit }>(),
);

export const getProgramVisitBySummaryError = createAction(
  '[Program Visit] Get Program Visit By Summary Error',
  props<{ error: any }>(),
);

export const updateProgramVisitCarePlan = createAction(
  '[Program Visit] Update Program Visit Care Plan',
  props<{ programCarePlanId: string; carePlan: string }>(),
);

export const updateProgramVisitCarePlanSuccess = createAction(
  '[Program Visit] Update Program Visit Care Plan Success',
  props<{ programCarePlan: ProgramCarePlan }>(),
);

export const updateProgramVisitCarePlanError = createAction(
  '[Program Visit] Update Program Visit Care Plan Error',
  props<{ error: any }>(),
);

export const updateProgramVisit = createAction(
  '[Program Visit] Update Program Visit',
  props<{ id: number; formData?: any; summaryOfVisit?: string }>(),
);

export const updateProgramVisitSuccess = createAction(
  '[Program Visit] Update Program Visit Success',
  props<{ programVisit: ProgramVisit }>(),
);

export const updateProgramVisitError = createAction(
  '[Program Visit] Update Program Visit Error',
  props<{ error: any }>(),
);

export const updateProgramForAppointment = createAction(
  '[Program Visit] Update Program For Appointment',
  props<{ appointmentId: string; programId: string }>(),
);

export const updateProgramForAppointmentSuccess = createAction(
  '[Program Visit] Update Program For Appointment Success',
  props<{ programEnrollment: ProgramEnrollment }>(),
);

export const updateProgramForAppointmentError = createAction(
  '[Program Visit] Update Program For Appointment Error',
  props<{ error: any }>(),
);
