import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'omg-document-summary-feedback-thanks',
  templateUrl: './document-summary-feedback-thanks.component.html',
  styleUrls: ['./document-summary-feedback-thanks.component.scss'],
  animations: [
    trigger('thanksAnimation', [
      transition(':leave', [
        style({ height: 300, opacity: 1 }),
        animate('0.5s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class DocumentSummaryFeedbackThanksComponent {}
