import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'omg-sidecar-smart-draft-banner',
  templateUrl: './sidecar-smart-draft-banner.html',
  styleUrls: ['./sidecar-smart-draft-banner.component.scss'],
})
export class SidecarSmartDraftBannerComponent {
  @Output() openSmartDraft = new EventEmitter();
  get smartDraftInfoTitle() {
    return 'Smart Draft (Beta)';
  }
}
