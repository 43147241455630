/* Possible circular dependency - possible solution: Move note type service into core. */
import { NoteTypeTags } from '@app/features/workspace/shared/note-types.type';
import { PatientAllergyReactionsResponse } from '@app/modules/allergies/shared/allergies.type';
import { PatientMedicationResponse } from '@app/modules/medications/shared/patient-medications-api.type';
import { NoteDocumentResponse } from '@app/modules/note/shared/note-api.type';
import {
  ProblemAtttachmentResponse,
  ProblemResponse,
} from '@app/modules/problems/shared/problems-api.type';
import { TodoResponse } from '@app/modules/todo/shared/todo-api.type';

import { SummaryChronicCareBillingPrograms } from './summaries.type';

export type SummaryCreatedByResponse = Partial<{
  id: number;
  name: string;
}>;

export type SummaryRedactedByResponse = Partial<{
  id: number;
  name: string;
}>;

export type SummaryProviderRecommendationS3PointerResponse = Partial<{
  id: number;
  bucket: string;
  key: string;
  title: string;
  content_type: string;
  content_length: number;
}>;

export type SummaryProviderRecommendationResponse = Partial<{
  id: number;
  body: string;
  subject_line: string;
  published: boolean;
  s3_pointers: SummaryProviderRecommendationS3PointerResponse[];
}>;

export type SummarySignedByResponse = Partial<{
  id: number;
  name: string;
}>;

export type SummaryNoteResponse = Partial<{
  id: number;
  total_comments: number;
}>;

export type SummaryNoteTypeResponse = Partial<{
  name: string;
  system_name: string;
  time_based_visit_billable: boolean;
  tags: NoteTypeTags[];
}>;

export interface SummaryPatientAllergiesResponse
  extends PatientAllergyReactionsResponse {
  comment: string;
  id: number;
  name: string;
  severity_code?: number;
  fuzzy_started_on?: string;
}

export type SummaryProblemTypeResponse = Partial<{
  clinical_description: string;
  id: number;
  importance: number;
}>;

export type SummaryProblemCodeLocationResponse = Partial<{
  id: number;
  name: string;
}>;

export type SummaryProblemResponse = Partial<{
  a_and_p: string;
  brief_comment: string;
  code: string;
  id: number;
  onset: string;
  problem: ProblemResponse;
  problem_history_id: number;
  problem_type: SummaryProblemTypeResponse;
  problem_code_location: SummaryProblemCodeLocationResponse;
  resolution: string;
  summary: string;
  attachments: ProblemAtttachmentResponse;
}>;

export type SummaryProblemAtttachmentResponse = Partial<{
  patient_medications: PatientMedicationResponse[];
  documents: NoteDocumentResponse[];
}>;

export type SummaryHealthBackgroundResponse = Partial<{
  family_data: { notes: string };
  health_background_history_id: number;
  illnesses_and_surgeries: { notes: string };
  social_data: { notes: string };
}>;

export type SummarySmokingStatusResponse = Partial<{
  display_description: string;
  fuzzy_started_on: string;
}>;

export type SummaryImplantableDevicesResponse = Partial<{
  device_type: string;
  insertion_date: string;
}>;

export type SummaryFamilyMemberConditionsResponse = Partial<{
  id: number;
  description: string;
  age_of_onset: string;
}>;

export interface SummaryFamilyHealthHistoryResponse {
  id: number;
  legacy_note: string;
  display_name: string;
  age: string;
  updated_at: string;
  sex: string;
  deceased: boolean;
  family_member_conditions: SummaryFamilyMemberConditionsResponse[];
}

export type SummaryHealthGoalActionResponse = Partial<{
  id: number;
  name: string;
  unique_name: string;
}>;

export type SummaryHealthGoalScreeningResponseEvent = Partial<{
  id: number;
  name: string;
}>;

export type SummaryHealthGoalScreeningResponse = Partial<{
  date_fuzzy: string;
  event: SummaryHealthGoalScreeningResponseEvent;
  id: number;
  result: string;
}>;

export type SummaryHealthGoalTypeResponse = Partial<{
  active: boolean;
  decision_support: string;
  id: number;
  name: string;
}>;

export type SummaryServiceTicketItemResponse = Partial<{
  id: number;
  item_class: string;
  indications: string[];
  cpts: string[];
  summary: string;
  title: string;
  hide_from_note: boolean;
}>;

export type SummaryHealthGoalResponse = Partial<{
  comment: string;
  declined: boolean;
  due_at: string;
  health_goal_action: SummaryHealthGoalActionResponse;
  health_goal_history_id: number;
  health_goal_screenings: SummaryHealthGoalScreeningResponse[];
  health_goal_type: SummaryHealthGoalTypeResponse;
  id: number;
  indicated: boolean;
}>;

export type SummaryHealthMaintenanceNoteResponse = Partial<{
  content: string;
  health_maintenance_note_history_id: number;
}>;

export type SummaryMedicationResponse = Partial<{
  id: number;
  name: string;
  regimen: string;
}>;

export type SummaryVaccinationResponse = Partial<{
  given_on_with_precision: string;
  id: number;
  name: string;
  step: string;
}>;

export type SummaryAttachableFileResponse = Partial<{
  id: number;
  url: string;
  download_path: string;
  viewing_path: string;
  thumb_viewing_path: string;
  name: string;
  size: number;
  type: string;
  last_modified_at: string;
}>;

export type SummaryAddendumResponse = Partial<{
  id: number;
  content: string;
  signed_at: string;
  co_signed: boolean;
  signed_by_name: string;
  signed_by_role: string;
  summary: { todos: TodoResponse[] };
}>;

export type SummaryCosignTaskAssigneeResponse = Partial<{
  id: number;
  identifier: string;
  name: string;
  out_until: string;
  role: string;
}>;

export type SummaryTodoResponse = Partial<{
  id: number;
  name: string;
}>;

export type SummaryAddendumTodoAssigneeResponse = Partial<{
  id: number;
  identifier: string;
  name: string;
  role: string;
}>;

export type SummaryAddendumTodoResponse = Partial<{
  todo: {
    assignee: SummaryAddendumTodoAssigneeResponse;
    comments: string;
    finish_label: string;
    high_priority: boolean;
    id: number;
    name: string;
    owner_id: number;
    state: string;
  };
}>;

export type SummaryAppointmentResponse = Partial<{
  id: number;
  reason: string;
  start_at: string;
  end_at: string;
  duration: any;
  type: string;
  avs_visible: boolean;
  cosignature_required: boolean;
  state: string;
}>;

export type SummaryChronicCareBillingProgramResponse = Partial<{
  id: string;
  display_name: string;
}>;

export type SummaryResponse = Partial<{
  active_problems?: SummaryProblemResponse[];
  assessed_problems?: SummaryProblemResponse[];
  attachable_files?: SummaryAttachableFileResponse[];
  cosign_task_assignee?: SummaryCosignTaskAssigneeResponse;
  created_at: string;
  created_by: SummaryCreatedByResponse;
  created_for_child?: boolean;
  created_for_infant?: boolean;
  family_members_histories?: SummaryFamilyHealthHistoryResponse[];
  health_background?: SummaryHealthBackgroundResponse;
  health_goals?: SummaryHealthGoalResponse[];
  health_maintenance_note?: SummaryHealthMaintenanceNoteResponse;
  id: number;
  implantable_devices_history?: SummaryImplantableDevicesResponse[];
  vitals?: any[];
  medical_history?: boolean;
  medications?: SummaryMedicationResponse[];
  no_known_allergies?: boolean;
  no_medications?: boolean;
  note: SummaryNoteResponse;
  note_type: SummaryNoteTypeResponse;
  objective?: string;
  patient_allergies?: SummaryPatientAllergiesResponse[];
  patient_id: number;
  resolved_problems?: SummaryProblemResponse[];
  service_ticket_items?: SummaryServiceTicketItemResponse[];
  visit_procedure_id?: number;
  signed: boolean;
  signed_at: string;
  smoking_status?: SummarySmokingStatusResponse;
  subject: string;
  subjective?: string;
  summary_addendums?: SummaryAddendumResponse[];
  time_based_visit?: boolean;
  todos?: SummaryTodoResponse[];
  signed_by: SummarySignedByResponse;
  vaccinations?: SummaryVaccinationResponse[];
  appointment?: SummaryAppointmentResponse;
  redacted_at: string;
  redacted_by: SummaryRedactedByResponse;
  billing_procedure_suggestions?: boolean;
  summary_provider_recommendation?: SummaryProviderRecommendationResponse;
  has_program_visit?: boolean;
  chronic_care_billing_programs?: SummaryChronicCareBillingPrograms[];
}>;

export type DeleteServiceTicketItemResponse = Partial<{
  deleteServiceTicketItem: {
    success: boolean;
    errors: string[];
  };
}>;

export type StartVisitFromNoteResponse = Partial<{
  startVisitFromNote: {
    success: boolean;
    errors: string[];
  };
}>;

export const SummaryAssociationType = 'Note';
