import { Inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

import { windowToken } from '@app/shared/window/window.service';
import { filterTruthy } from '@app/utils';

/*
  RouteTracker is used for the login/auth process.
  It tracks every route change and saves it (if valid path) to sessionStorage.
  The saved path is used as a path to redirect to after logging in.
*/
@Injectable()
export class RouteTracker {
  private whitelistedRedirectPaths: string[] = ['schedule', 'patients'];

  constructor(
    private router: Router,
    @Inject(windowToken) private window: Window,
  ) {
    this.trackAndSetRedirectPaths().subscribe();
  }

  trackAndSetRedirectPaths() {
    return this.router.events.pipe(
      filterTruthy(),
      filter<NavigationStart>(event => event instanceof NavigationStart),
      filter(event => this.filterRedirectablePaths(event.url)),
      tap(event => this.setRedirectPath(event.url)),
    );
  }

  private filterRedirectablePaths(url: string) {
    const shouldRedirect = this.whitelistedRedirectPaths.reduce(
      (willRedirect, whitelistedPath) =>
        url.startsWith(`/${whitelistedPath}`) ? true : willRedirect,
      false,
    );
    return shouldRedirect;
  }

  private setRedirectPath(url: string) {
    this.window.sessionStorage.setItem('path', url);
  }
}
