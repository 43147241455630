import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import { fromEvent, merge } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import {
  AnalyticsEvent,
  TrackEventProperties,
} from '@app/core/analytics/analytics.type';
import { Template } from '@app/modules/messaging/shared/template-insertion.type';
import { ChartVariablesService } from '@app/shared/services/chart-variables.service';

import {
  getAnalyticsProperties,
  insertionActivated,
  insertionDestroyedWithoutTemplateSelection,
  InsertionState,
  insertionTemplateSelected,
  isWhitespace,
  quillFocused,
  removeSlashTerm,
} from './inline-insertion.utils';

@Directive({
  selector: '[omgInsertionTriggers]',
})
export class InlineInsertionDirective implements OnChanges {
  /**
   * Analaytics event props to report out on insertion-related events.
   * These default values ensure it's clear to a mixpanel analyst that
   * there's a ui component lacking the event props.
   */
  @Input() insertionEventProps: Partial<TrackEventProperties> = {
    component: 'unspecified',
    subcomponent: 'unspecified',
  };
  @Input() insertionQuill: QuillEditorComponent;
  @Input() singleLine: boolean;

  @Input() templateType = 'text';
  @Input() selectedTemplate: Template;
  @Input() insertionState: InsertionState;
  @Input() isInlineInsertionActive: boolean;
  @Input() isQuillInFocus: boolean;
  @Input() modules: any;

  constructor(
    private analytics: AnalyticsService,
    private chartVariablesService: ChartVariablesService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (insertionTemplateSelected(changes) && this.insertionState) {
      this.onItemSelect(this.selectedTemplate, this.insertionState);
    }

    if (
      insertionDestroyedWithoutTemplateSelection(changes) &&
      this.insertionState
    ) {
      if (!isWhitespace(this.insertionState.searchTerm)) {
        removeSlashTerm(
          this.insertionQuill,
          this.insertionState.startIndex,
          this.insertionState.searchTerm,
        );
      }
    }

    if (insertionActivated(changes)) {
      this.trackAnalyticsEvent(AnalyticsEvent.TemplateInlineMenuActivated);
    }

    if (quillFocused(changes)) {
      this.ifTabFocusMoveCursorToEnd(this.insertionQuill);
    }
  }

  onItemSelect(template: Template, insertionState: InsertionState) {
    removeSlashTerm(
      this.insertionQuill,
      insertionState.startIndex,
      insertionState.searchTerm,
    );

    if (this.templateType === 'text') {
      const templateBody = this.singleLine
        ? template.body
            .replace(/\n/g, ' ')
            .split(' ')
            .filter(Boolean)
            .join(' ')
            .trim()
        : template.body.trim();
      this.chartVariablesService
        .populate(templateBody, template, this.insertionEventProps)
        .pipe(take(1))
        .subscribe(populated => {
          this.insertionQuill.quillEditor.insertText(
            insertionState.startIndex - 1,
            populated,
            'user',
          );
        });
    }

    if (this.templateType === 'message') {
      this.chartVariablesService
        .populate(template.body.trim(), template, this.insertionEventProps)
        .pipe(take(1))
        .subscribe(populated => {
          this.insertionQuill.quillEditor.clipboard.dangerouslyPasteHTML(
            insertionState.startIndex - 1,
            populated,
            'user',
          );
        });
    }

    this.insertionQuill.quillEditor.setSelection(
      insertionState.startIndex + template.body.trim().length - 1,
      0,
    );

    this.trackAnalyticsEvent(AnalyticsEvent.TemplateInlineInserted, {
      template,
      term: insertionState.searchTerm,
    });
  }

  private ifTabFocusMoveCursorToEnd(insertionQuill: QuillEditorComponent) {
    fromEvent<KeyboardEvent>(document, 'keyup')
      .pipe(
        take(1),
        filter(event => event.key === 'Tab'),
        tap(() => {
          const editor = insertionQuill.quillEditor;
          const numChars = editor.getLength();
          editor.setSelection(numChars, 0, 'silent');
        }),
        takeUntil(merge(insertionQuill.onBlur, fromEvent(document, 'mouseup'))),
      )
      .subscribe();
  }

  private trackAnalyticsEvent(
    analyticsEvent: AnalyticsEvent,
    options: {
      template?: Template;
      term?: string;
    } = {},
  ) {
    this.analytics.track(
      analyticsEvent,
      getAnalyticsProperties(options, this.insertionEventProps),
    );
  }
}
