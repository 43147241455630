import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { ReferenceDataKeys } from '@app/modules/reference-data/shared/reference-data.type';
import { ReferenceDataSelectors } from '@app/modules/reference-data/store/reference-data.selectors';
import { AutoCompleteComponent } from '@app/shared';

import { DenialReason } from '../../../../features/renewals/shared/renewals.type';

@Component({
  selector: 'omg-rx-item-deny-drawer',
  templateUrl: './rx-item-deny-drawer.component.html',
  styleUrls: ['./rx-item-deny-drawer.component.css'],
})
export class RxItemDenyDrawerComponent implements OnInit {
  @Input() isChange: boolean;
  @Input() isRenewal: boolean;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() deny = new EventEmitter<DenialReason>();
  @ViewChild('denialReasonSearch', { static: true })
  denialReasonSearch: AutoCompleteComponent;

  denialReasons$: Observable<any>;
  denialReason: UntypedFormControl = new UntypedFormControl(null);

  constructor(private referenceDataSelectors: ReferenceDataSelectors) {}

  ngOnInit() {
    if (this.isRenewal) {
      this.denialReasons$ = this.referenceDataSelectors.get<any>(
        ReferenceDataKeys.renewalDenialReasons,
      );
    }
    if (this.isChange) {
      this.denialReasons$ = this.referenceDataSelectors.get<any>(
        ReferenceDataKeys.changeDenialReasons,
      );
    }
  }

  /* istanbul ignore next */
  onCancel() {
    this.cancel.emit();
  }

  onDeny() {
    this.deny.emit(this.denialReason.value);
  }

  get disabled() {
    return !this.denialReason.value;
  }

  addCustomDenialReason = (description: string) => {
    const customDenialReason = { code: null, description };
    this.denialReason.patchValue(customDenialReason);
    return customDenialReason;
  };
}
