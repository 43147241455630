import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SearchService } from '@app/core';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';

import { mapTaskAssigneeSearchResultToEntity } from './todo-api-mappers';

@Injectable()
export class TaskAssignmentService {
  constructor(
    private searchService: SearchService,
    private config: ConfigService,
  ) {}

  searchTaskAssignees(text: string): Observable<any> {
    const query = new QueryBuilder('multi_match_with_fields_v6_strategy').build(
      text,
      {
        size: '8',
        fields: ['name'],
        operator: 'and',
        sort: ['_score', 'name.keyword'],
        index: [this.config.searchIndex('task_assignees')],
      },
    );
    query.body.query.bool.should = [
      { term: { role: { value: 'Group', boost: 10 } } },
    ];

    return this.searchService.search(query).pipe(
      map(response => {
        const hits = response.hits || {};
        const items = hits.hits || [];
        return items.map(mapTaskAssigneeSearchResultToEntity);
      }),
    );
  }
}
