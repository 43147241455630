// @ts-strict-ignore
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';

import { FullPrescriptionHistoryComponent } from '@app/features/prescription-history/components/full-prescription-history/full-prescription-history.component';
import { PrescriptionHistoryTabs } from '@app/features/prescription-history/shared/prescription-history.type';
import { PDMPService } from '@app/modules/shared-rx/services/pdmp.service';
import { DialogService } from '@app/shared/components/dialog';

import { PdmpNonParticipatingStatesResponse } from '../../shared/pdmp.type';
import {
  formatStateList,
  mapToPdmpNonParticipatingStates,
} from '../../shared/pdmp.utils';

export const pdmpNonParticipatingStatesQuery = gql`
  query GetPdmpNonParticipatingStates {
    pdmpNonParticipatingStates {
      id
      shortName
    }
  }
`;

export const patientPrimaryAddressStateQuery = gql`
  query GetPatientPrimaryAddress($id: ID!) {
    patient(id: $id) {
      id
      primaryAddress {
        id
        state
      }
    }
  }
`;

@Component({
  selector: 'omg-pdmp-dialogue-banner',
  templateUrl: './pdmp-dialogue-banner.component.html',
  styleUrls: ['./pdmp-dialogue-banner.component.scss'],
})
export class PdmpDialogueBannerComponent implements OnInit, OnDestroy {
  requiredStates$: Observable<string>;
  reportStates$: Observable<string>;
  nonReportStates$: Observable<string>;
  showCheckbox$: Observable<boolean>;

  isChecked: boolean;
  private cartId: number;
  private teardown = new Subject<void>();

  constructor(
    private dialogService: DialogService,
    private apollo: Apollo,
    private PDMPService: PDMPService,
  ) {}

  ngOnDestroy(): void {
    this.teardown.next();
    this.teardown.complete();
  }

  ngOnInit(): void {
    this.setupListeners();
  }

  onShowReportClick(): void {
    const dialogRef = this.dialogService.open(
      FullPrescriptionHistoryComponent,
      {
        autoFocus: true,
        disableClose: false,
      },
    );

    if (dialogRef) {
      // Change this to use @Inject(OMG_DIALOG_DATA)
      dialogRef.componentInstance.currentHistoryTab =
        PrescriptionHistoryTabs.pdmp.id;
      dialogRef.componentInstance.rxCartId = this.cartId;
    }
  }

  onConfirmReportViewedChange(): void {
    this.PDMPService.readyToSignAttested(this.isChecked);
  }

  private setupListeners(): void {
    const statesAndNonParticipation$ = combineLatest([
      this.PDMPService.controlledSubstanceStates$,
      this.nonParticipatingStates$,
    ]);

    this.reportStates$ = statesAndNonParticipation$.pipe(
      map(([states, nonParticipatingStates]) =>
        states.filter(state => !nonParticipatingStates.includes(state)),
      ),
      map(formatStateList),
    );
    this.nonReportStates$ = statesAndNonParticipation$.pipe(
      map(([states, nonParticipatingStates]) =>
        states.filter(state => nonParticipatingStates.includes(state)),
      ),
      map(formatStateList),
    );
    this.requiredStates$ = this.PDMPService.controlledSubstanceStates$.pipe(
      map(formatStateList),
    );

    this.PDMPService.readyToSignAttestationNeeded$
      .pipe(takeUntil(this.teardown))
      .subscribe(value => (this.isChecked = !value));

    this.PDMPService.cartId$
      .pipe(takeUntil(this.teardown))
      .subscribe(cartId => (this.cartId = cartId));

    this.showCheckbox$ = this.PDMPService.attestationInCheckoutDialog$.pipe(
      map(enabled => !enabled),
    );
  }

  private get nonParticipatingStates$(): Observable<string[]> {
    return this.apollo
      .use('onelife')
      .query<PdmpNonParticipatingStatesResponse>({
        query: pdmpNonParticipatingStatesQuery,
      })
      .pipe(map(mapToPdmpNonParticipatingStates));
  }
}
