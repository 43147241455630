import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';

import { selectRxCartState } from '@app/modules/rx-cart/store/rx-cart-store-shared';
import { NewRxCartState } from '@app/modules/rx-cart/store/rx-cart.reducer';
import { sortBy } from '@app/utils';

import {
  PatientPharmacy,
  PharmacyOption,
} from '../shared/patient-pharmacy.type';
import { adapter, PatientPharmacyState } from './patient-pharmacy.reducer';

export const selectPatientPharmacyState = createSelector(
  selectRxCartState,
  (state: NewRxCartState) => state && state.patientPharmacies,
);

const { selectEntities, selectAll, selectIds } = adapter.getSelectors();

export const selectPatientPharmacyIds = createSelector(
  selectPatientPharmacyState,
  selectIds,
);

export const selectPatientPharmacyEntities = createSelector(
  selectPatientPharmacyState,
  selectEntities,
);

export const selectAllPatientPharmacies = createSelector(
  selectPatientPharmacyState,
  selectAll,
);

export const selectStaticPharmacyOptions = createSelector(
  selectPatientPharmacyState,
  (state: PatientPharmacyState) => state.staticPharmacyOptions,
);

export const selectPatientPharmacyStaticOptions = createSelector(
  selectStaticPharmacyOptions,
  (staticOptions: PharmacyOption[]) =>
    staticOptions.map(options => ({
      id: options.id,
      pharmacy: options,
    })) as PatientPharmacy[],
);

export const selectPatientPharmacyOptions = createSelector(
  selectAllPatientPharmacies,
  selectPatientPharmacyStaticOptions,
  (
    patientPharmacies: PatientPharmacy[],
    staticOptions: PatientPharmacy[],
  ): PatientPharmacy[] =>
    sortBy(
      patientPharmacy =>
        patientPharmacy &&
        patientPharmacy.pharmacy &&
        patientPharmacy.pharmacy.id,
      patientPharmacies,
    ).concat(staticOptions),
);

export const selectPatientPharmacyById = createSelector(
  selectPatientPharmacyState,
  (state: PatientPharmacyState, params) =>
    (params && params.id && state.entities[params.id]) || null,
);

export const selectPharmacy = createSelector(
  selectPatientPharmacyById,
  (patientPharmacy: PatientPharmacy) =>
    patientPharmacy && patientPharmacy.pharmacy,
);

export const selectPatientPharmacyByPharmacyId = createSelector(
  selectPatientPharmacyOptions,
  (pharmacies: PatientPharmacy[], { id }) =>
    pharmacies.find(p => p.pharmacy && p.pharmacy.id === id) || null,
);

export const selectIsPending = createSelector(
  selectPatientPharmacyState,
  (state: PatientPharmacyState) => state.pending,
);

export const selectErrors = createSelector(
  selectPatientPharmacyState,
  (state: PatientPharmacyState) => state.error,
);

/* istanbul ignore next */
@Injectable({
  providedIn: 'root',
})
export class PatientPharmacySelectors {
  constructor(private store: Store<NewRxCartState>) {}

  get itemIds() {
    return this.store.pipe(select(selectPatientPharmacyIds));
  }

  get entities() {
    return this.store.pipe(select(selectPatientPharmacyEntities));
  }

  get patientPharmacies() {
    return this.store.pipe(select(selectAllPatientPharmacies));
  }

  get patientPharmaciesDropdownOptions() {
    return this.store.pipe(select(selectPatientPharmacyOptions));
  }

  patientPharmacy(id: number) {
    return this.store.pipe(select(selectPatientPharmacyById, { id }));
  }

  pharmacy(id: number) {
    return this.store.pipe(select(selectPatientPharmacyByPharmacyId, { id }));
  }

  get isPending() {
    return this.store.pipe(select(selectIsPending));
  }

  get errors() {
    return this.store.pipe(select(selectErrors));
  }
}
