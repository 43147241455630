import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { PresenceComponent } from './presence.component';
import { PresenceService } from './presence.service';

const components = [PresenceComponent];

@NgModule({
  imports: [SharedModule],
  exports: [...components],
  declarations: [...components],
  providers: [PresenceService],
})
export class PresenceModule {}
