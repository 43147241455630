import { Injectable } from '@angular/core';

import { GetProfileQueryService } from '@app/modules/messaging/shared/smart-draft.onelife.generated';

@Injectable()
export class ProfileService {
  constructor(private getProfileQuery: GetProfileQueryService) {}

  get() {
    return this.getProfileQuery.watch({}, { fetchPolicy: 'network-only' });
  }
}
