<div om-layout
     om-layout-align="space-between"
     omgTheme>
  <div om-layout="vertical"
       om-layout-gutter
       om-layout-fill>
    <strong class="cart-item-title"
            *ngIf="heading; else bodyText">
      {{ name }}
      {{ description }}
    </strong>
    <omg-medication-dea-schedule [deaSchedule]="deaSchedule"></omg-medication-dea-schedule>
    <omg-collapse om-collapse
                  [ngClass]="{'-error':forceEdit}"
                  (expandedChange)="onEditorExpand($event)"
                  #regimenEditCollapseRef="omgCollapse"
                  *ngIf="editable; else staticInstructions">
      <a om-layout-gutter
         omgCollapseToggle="expand"
         class="om-link -plain-text">{{ instructions }}
        <i class="fa fa-pencil"></i>
      </a>
      <i *ngIf="forceEdit"
         omgTooltip
         [tooltipText]="instructionsTooLongMessage"
         tooltipPosition="right"
         class="fa fa-exclamation-circle"></i>
      <omg-expanded>
        <omg-regimen-editor *ngIf="regimenEditCollapseRef.expanded"
                            [medication]="medication"
                            (regimenUpdate)="regimenUpdate.emit($event)"
                            (collapse)="regimenEditCollapseRef.collapse()"></omg-regimen-editor>
      </omg-expanded>
    </omg-collapse>
  </div>
  <div *ngIf="availabilityStatusLabel">
    <div class="om-label padding-half dispensability-label"
         data-cy="availability-status-label">
      {{ availabilityStatusLabel }}
    </div>
  </div>
  <div *ngIf="deletable">
    <i class="om-icon fa fa-lg"
       [ngClass]="(loading$ | ngrxPush) ? 'fa-spinner fa-pulse' : 'fa-trash-o clickable'"
       (click)="onDelete()"></i>
  </div>
</div>

<ng-template #bodyText>
  <strong>
    {{ name }}
    {{ description | omgWrapText:50 }}
  </strong>
</ng-template>
<ng-template #staticInstructions>
  <span>{{instructions}}</span>
</ng-template>
