import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { DialogRef, OMG_DIALOG_DATA } from '@app/shared/components/dialog';
import { windowToken } from '@app/shared/window/window.service';

import { HelpRequestFormService } from '../help-request-form.service';
import {
  HelpRequestDetails,
  HelpRequestOptions,
  HelpRequestService,
} from '../help-request.service';
import { OrderHelpRequestForm } from '../order-help-request-form';

@Component({
  selector: 'omg-order-help-request-modal',
  templateUrl: './order-help-request-modal.component.html',
  styleUrls: ['./order-help-request-modal.component.scss'],
})
export class OrderHelpRequestModalComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  form: OrderHelpRequestForm;
  saving: boolean;
  helpOutWikiLink =
    'https://sites.google.com/onemedical.com/1life-remote-workflow/remote-care/helpout';

  constructor(
    public dialogRef: DialogRef<OrderHelpRequestModalComponent, boolean>,
    @Inject(OMG_DIALOG_DATA) public requestOptions: HelpRequestOptions,
    private helpRequestService: HelpRequestService,
    private helpRequestFormService: HelpRequestFormService,
    @Inject(windowToken) private window: Window,
  ) {}

  ngOnInit(): void {
    this.form = this.helpRequestFormService.buildOrderHelpRequestForm(
      this.unsubscribe$,
    );
  }

  sendRequest() {
    const helpRequestDetails: HelpRequestDetails = {
      options: {
        ...this.requestOptions,
        actionUrl: this.window.location.href,
      },
      ...this.form.value,
    };
    this.saving = true;
    this.helpRequestService
      .getHelp(helpRequestDetails)
      .pipe(take(1))
      .subscribe(() => {
        this.saving = false;
        this.close();
      });
  }

  close() {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
