import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'omg-smart-draft-buttons',
  templateUrl: './smart-draft-buttons.html',
  styleUrls: ['./smart-draft-buttons.component.scss'],
})
export class SmartDraftButtonsComponent implements OnChanges {
  @Output() createSmartDraft = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Input() loading!: boolean;
  @Input() draftComplete!: boolean;

  // This makes sure the component updates visually to show / hide the loading spinner when the parent
  // component passes down a new "loading" value when the polling for the smart draft completes
  ngOnChanges(changes: SimpleChanges): void {
    if (
      !!changes.loading &&
      changes.loading.currentValue?.value !==
        changes.loading.previousValue?.value
    ) {
      this.loading = changes.loading.currentValue?.value;
    }
  }
}
