import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppState } from '@app/core/store/store/app-store.reducer';
import { isNotNullOrUndefined } from '@app/utils';

import { getProgramVisitBySummary } from './../../store/program-visit.actions';
import { ProgramVisitSelectors } from './../../store/program-visit.selectors';
import { ProgramVisit } from '../../shared/program-visit.type';
import { ChronicCareManagementFormComponent } from '../chronic-care-management-form/chronic-care-management-form.component';

@Component({
  selector: 'omg-chronic-care-management',
  templateUrl: './chronic-care-management.component.html',
})
export class ChronicCareManagementComponent implements OnInit, OnDestroy {
  @Input() summaryId: number;
  @Input() completed: boolean;

  @ViewChild(ChronicCareManagementFormComponent)
  chronicCareForm: ChronicCareManagementFormComponent;

  programVisit$: Observable<ProgramVisit>;
  loadingProgramVisit$: Observable<boolean>;

  private unsubscribe = new Subject<void>();

  constructor(
    private programVisitSelectors: ProgramVisitSelectors,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.store.dispatch(
      getProgramVisitBySummary({ summaryId: this.summaryId }),
    );

    this.programVisit$ = this.programVisitSelectors
      .get()
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined());

    this.loadingProgramVisit$ = this.programVisitSelectors.loading(
      getProgramVisitBySummary,
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get chronicCareFormValid(): boolean {
    return this.chronicCareForm.chronicCareFormValid;
  }

  markChronicCareFormTouched() {
    if (this.chronicCareForm) {
      this.chronicCareForm.markFormAsTouched();
    }
  }
}
