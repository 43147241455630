import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';

import { FsaAction } from '@app/core';
import { CorrelationId, uuid } from '@app/utils';

import {
  PatientChangeRequest,
  PatientMedication,
  PatientMedicationForm,
  PatientMedicationId,
  PatientMedicationPrescriptionHistoryItem,
  PatientMedicationRegimen,
  PatientRenewalRequest,
} from '../shared/patient-medications.type';
import { PatientMedicationState } from './patient-medication.reducer';

type PatientId = number;
type MedicationId = number;
type PatientMedicationError = any;
type PrescriptionHistoryError = any;
type PatiendMedicationRegimensError = any;

export enum PatientMedicationActionTypes {
  LoadPatientMedications = '[PatientMedication] Load PatientMedications',
  LoadPatientMedicationsSuccess = '[PatientMedication] Load PatientMedications Success',
  LoadPatientMedicationsError = '[PatientMedication] Load PatientMedications Error',
  AddPatientMedication = '[PatientMedication] Add PatientMedication',
  AddPatientMedicationSuccess = '[PatientMedication] Add PatientMedication Success',
  AddPatientMedicationError = '[PatientMedication] Add PatientMedication Error',
  UpdatePatientMedication = '[PatientMedication] Update PatientMedication',
  UpdatePatientMedicationSuccess = '[PatientMedication] Update PatientMedication Success',
  UpdatePatientMedicationError = '[PatientMedication] Update PatientMedication Error',
  DeletePatientMedication = '[PatientMedication] Delete PatientMedication',
  DeletePatientMedicationSuccess = '[PatientMedication] Delete PatientMedication Success',
  DeletePatientMedicationError = '[PatientMedication] Delete PatientMedication Error',
  DiscontinuePatientMedication = '[PatientMedication] Discontinue PatientMedication',
  DiscontinuePatientMedicationSuccess = '[PatientMedication] Discontinue PatientMedication Success',
  DiscontinuePatientMedicationError = '[PatientMedication] Discontinue PatientMedication Error',
  ResumePatientMedication = '[PatientMedication] Resume PatientMedication',
  ResumePatientMedicationSuccess = '[PatientMedication] Resume PatientMedication Success',
  ResumePatientMedicationError = '[PatientMedication] Resume PatientMedication Error',
  WritePatientMedicationRx = '[PatientMedication] Write PatientMedication Rx',
  WritePatientMedicationRxSuccess = '[PatientMedication] Write PatientMedication Rx Success',
  WritePatientMedicationRxError = '[PatientMedication] Write PatientMedication Rx Error',
  ClearPatientMedications = '[PatientMedication] Clear PatientMedications',
  LoadPatientMedicationPrescriptionHistory = '[PatientMedication] Load PrescriptionHistory',
  LoadPatientMedicationPrescriptionHistorySuccess = '[PatientMedication] Load PrescriptionHistory Success',
  LoadPatientMedicationPrescriptionHistoryError = '[PatientMedication] Load PrescriptionHistory Error',
  LoadPatientMedicationRegimenHistory = '[PatientMedication] Load PatientMedicationRegimenHistoryItem',
  LoadPatientMedicationRegimenHistorySuccess = '[PatientMedication] Load PatientMedicationRegimenHistoryItem Success',
  LoadPatientMedicationRegimenHistoryError = '[PatientMedication] Load LoadPatientMedicationRegimenHistory Error',
  RequestRenewal = '[PatientMedication] Request Renewal',
  RequestRenewalSuccess = '[PatientMedication] Request Renewal Success',
  RequestRenewalError = '[PatientMedication] Request Renewal Error',
  RequestChange = '[PatientMedication] Request Change',
  RequestChangeSuccess = '[PatientMedication] Request Change Success',
  RequestChangeError = '[PatientMedication] Request Change Error',
}

export class LoadPatientMedications implements FsaAction<PatientId> {
  readonly type = PatientMedicationActionTypes.LoadPatientMedications;

  constructor(public payload: PatientId) {}
}

export class LoadPatientMedicationsSuccess
  implements FsaAction<PatientMedication[]>
{
  readonly type = PatientMedicationActionTypes.LoadPatientMedicationsSuccess;
  error = false;

  constructor(public payload: PatientMedication[]) {}
}

export class LoadPatientMedicationsError
  implements FsaAction<PatientMedicationError>
{
  readonly type = PatientMedicationActionTypes.LoadPatientMedicationsError;
  error = true;

  constructor(public payload: PatientMedicationError) {}
}

export class LoadPatientMedicationPrescriptionHistory
  implements FsaAction<MedicationId>
{
  readonly type =
    PatientMedicationActionTypes.LoadPatientMedicationPrescriptionHistory;

  constructor(public payload: MedicationId) {}
}

export class LoadPatientMedicationPrescriptionHistorySuccess
  implements FsaAction<PatientMedicationPrescriptionHistoryItem[]>
{
  readonly type =
    PatientMedicationActionTypes.LoadPatientMedicationPrescriptionHistorySuccess;

  constructor(
    public payload: PatientMedicationPrescriptionHistoryItem[],
    public meta: { id: number },
  ) {}
}

export class LoadPatientMedicationPrescriptionHistoryError
  implements FsaAction<PrescriptionHistoryError>
{
  readonly type =
    PatientMedicationActionTypes.LoadPatientMedicationPrescriptionHistoryError;

  constructor(
    public payload: PrescriptionHistoryError,
    public meta: { id: number },
  ) {}
}

export class LoadPatientMedicationRegimenHistory
  implements FsaAction<MedicationId>
{
  readonly type =
    PatientMedicationActionTypes.LoadPatientMedicationRegimenHistory;

  constructor(public payload: MedicationId) {}
}

export class LoadPatientMedicationRegimenHistorySuccess
  implements FsaAction<PatientMedicationRegimen[]>
{
  readonly type =
    PatientMedicationActionTypes.LoadPatientMedicationRegimenHistorySuccess;

  constructor(
    public payload: PatientMedicationRegimen[],
    public meta: { id: number },
  ) {}
}

export class LoadPatientMedicationRegimenHistoryError
  implements FsaAction<PatiendMedicationRegimensError>
{
  readonly type =
    PatientMedicationActionTypes.LoadPatientMedicationRegimenHistoryError;

  constructor(
    public payload: PatiendMedicationRegimensError,
    public meta: { id: number },
  ) {}
}

export class AddPatientMedication implements FsaAction<PatientMedicationForm> {
  readonly type = PatientMedicationActionTypes.AddPatientMedication;

  constructor(public payload: PatientMedicationForm) {}
}

export class AddPatientMedicationSuccess
  implements FsaAction<PatientMedication>
{
  readonly type = PatientMedicationActionTypes.AddPatientMedicationSuccess;
  error = false;

  constructor(public payload: PatientMedication) {}
}

export class AddPatientMedicationError
  implements FsaAction<PatientMedicationError>
{
  readonly type = PatientMedicationActionTypes.AddPatientMedicationError;
  error = true;

  constructor(
    public payload: PatientMedicationError,
    public meta: { id: number },
  ) {}
}

export class UpdatePatientMedication
  implements FsaAction<PatientMedicationForm>
{
  readonly type = PatientMedicationActionTypes.UpdatePatientMedication;

  constructor(public payload: PatientMedicationForm) {}
}

export class UpdatePatientMedicationSuccess
  implements FsaAction<PatientMedicationRegimen>
{
  readonly type = PatientMedicationActionTypes.UpdatePatientMedicationSuccess;
  error = false;

  constructor(
    public payload: PatientMedicationRegimen,
    public meta: { id: number },
  ) {}
}

export class UpdatePatientMedicationError
  implements FsaAction<PatientMedicationError>
{
  readonly type = PatientMedicationActionTypes.UpdatePatientMedicationError;
  error = true;

  constructor(
    public payload: PatientMedicationError,
    public meta: { id: number },
  ) {}
}

export class DiscontinuePatientMedication
  implements FsaAction<PatientMedicationId>
{
  readonly type = PatientMedicationActionTypes.DiscontinuePatientMedication;

  constructor(public payload: PatientMedicationId) {}
}

export class DiscontinuePatientMedicationSuccess
  implements FsaAction<PatientMedication>
{
  readonly type =
    PatientMedicationActionTypes.DiscontinuePatientMedicationSuccess;
  error = false;

  constructor(public payload: PatientMedication) {}
}

export class DiscontinuePatientMedicationError
  implements FsaAction<PatientMedicationError>
{
  readonly type =
    PatientMedicationActionTypes.DiscontinuePatientMedicationError;
  error = true;

  constructor(
    public payload: PatientMedicationError,
    public meta: { id: PatientMedicationId },
  ) {}
}

export class ResumePatientMedication implements FsaAction<PatientMedicationId> {
  readonly type = PatientMedicationActionTypes.ResumePatientMedication;

  constructor(public payload: PatientMedicationId) {}
}

export class ResumePatientMedicationSuccess
  implements FsaAction<PatientMedication>
{
  readonly type = PatientMedicationActionTypes.ResumePatientMedicationSuccess;
  error = false;

  constructor(public payload: PatientMedication) {}
}

export class ResumePatientMedicationError
  implements FsaAction<PatientMedicationError>
{
  readonly type = PatientMedicationActionTypes.ResumePatientMedicationError;
  error = true;

  constructor(
    public payload: PatientMedicationError,
    public meta: { id: PatientMedicationId },
  ) {}
}

export class DeletePatientMedication implements FsaAction<PatientMedicationId> {
  readonly type = PatientMedicationActionTypes.DeletePatientMedication;

  constructor(public payload: PatientMedicationId) {}
}

export class DeletePatientMedicationSuccess
  implements FsaAction<PatientMedicationId>
{
  readonly type = PatientMedicationActionTypes.DeletePatientMedicationSuccess;
  error = false;

  constructor(public payload: PatientMedicationId) {}
}

export class DeletePatientMedicationError
  implements FsaAction<PatientMedicationError>
{
  readonly type = PatientMedicationActionTypes.DeletePatientMedicationError;
  error = true;

  constructor(
    public payload: PatientMedicationError,
    public meta: { id: PatientMedicationId },
  ) {}
}

export class WritePatientMedicationRx
  implements FsaAction<Partial<PatientMedicationForm>>
{
  readonly type = PatientMedicationActionTypes.WritePatientMedicationRx;

  constructor(
    public payload: Partial<PatientMedicationForm>,
    public meta: {
      correlationId: CorrelationId;
    },
  ) {}
}

export class WritePatientMedicationRxSuccess implements FsaAction<void> {
  readonly type = PatientMedicationActionTypes.WritePatientMedicationRxSuccess;
  error = false;

  constructor(public meta: { correlationId: CorrelationId }) {}
}

export class RequestRenewalError implements FsaAction<any> {
  readonly type = PatientMedicationActionTypes.RequestRenewalError;
  error = true;

  constructor(public payload: any) {}
}

export class RequestRenewal
  implements FsaAction<Partial<PatientRenewalRequest>>
{
  readonly type = PatientMedicationActionTypes.RequestRenewal;

  constructor(public payload: PatientRenewalRequest) {}
}

export class RequestRenewalSuccess implements FsaAction<void> {
  readonly type = PatientMedicationActionTypes.RequestRenewalSuccess;
  error = false;
}

export class RequestChange implements FsaAction<Partial<PatientChangeRequest>> {
  readonly type = PatientMedicationActionTypes.RequestChange;

  constructor(public payload: PatientChangeRequest) {}
}

export class RequestChangeSuccess implements FsaAction<void> {
  readonly type = PatientMedicationActionTypes.RequestChangeSuccess;
  error = false;
}

export class RequestChangeError implements FsaAction<any> {
  readonly type = PatientMedicationActionTypes.RequestChangeError;
  error = true;

  constructor(public payload: any) {}
}

export class WritePatientMedicationRxError
  implements FsaAction<PatientMedicationError>
{
  readonly type = PatientMedicationActionTypes.WritePatientMedicationRxError;
  error = true;

  constructor(
    public payload: PatientMedicationError,
    public meta: { correlationId: CorrelationId },
  ) {}
}

export class ClearPatientMedications implements Action {
  readonly type = PatientMedicationActionTypes.ClearPatientMedications;
}

export type PatientMedicationAction =
  | LoadPatientMedications
  | LoadPatientMedicationsSuccess
  | LoadPatientMedicationsError
  | LoadPatientMedicationPrescriptionHistory
  | LoadPatientMedicationPrescriptionHistorySuccess
  | LoadPatientMedicationPrescriptionHistoryError
  | LoadPatientMedicationRegimenHistory
  | LoadPatientMedicationRegimenHistorySuccess
  | LoadPatientMedicationRegimenHistoryError
  | AddPatientMedication
  | AddPatientMedicationSuccess
  | AddPatientMedicationError
  | UpdatePatientMedication
  | UpdatePatientMedicationSuccess
  | UpdatePatientMedicationError
  | DiscontinuePatientMedication
  | DiscontinuePatientMedicationSuccess
  | DiscontinuePatientMedicationError
  | DeletePatientMedication
  | DeletePatientMedicationSuccess
  | DeletePatientMedicationError
  | ResumePatientMedication
  | ResumePatientMedicationSuccess
  | ResumePatientMedicationError
  | WritePatientMedicationRx
  | WritePatientMedicationRxSuccess
  | WritePatientMedicationRxError
  | RequestRenewal
  | RequestRenewalSuccess
  | RequestRenewalError
  | RequestChange
  | RequestChangeSuccess
  | RequestChangeError
  | ClearPatientMedications;

@Injectable()
export class PatientMedicationActions {
  constructor(private store: Store<PatientMedicationState>) {}

  loadPatientMedications(patientId: number) {
    this.store.dispatch(new LoadPatientMedications(patientId));
  }

  loadPrescriptionHistory(medicationId: number) {
    this.store.dispatch(
      new LoadPatientMedicationPrescriptionHistory(medicationId),
    );
  }

  loadPatientMedicationRegimenHistory(medicationId: number) {
    this.store.dispatch(new LoadPatientMedicationRegimenHistory(medicationId));
  }

  addPatientMedication(patientMedication: PatientMedicationForm) {
    this.store.dispatch(new AddPatientMedication(patientMedication));
  }

  updatePatientMedication(patientMedication: PatientMedicationForm) {
    this.store.dispatch(new UpdatePatientMedication(patientMedication));
  }

  deletePatientMedication(medicationId: number) {
    this.store.dispatch(new DeletePatientMedication(medicationId));
  }

  discontinuePatientMedication(medicationId: number) {
    this.store.dispatch(new DiscontinuePatientMedication(medicationId));
  }

  resumePatientMedication(medicationId: number) {
    this.store.dispatch(new ResumePatientMedication(medicationId));
  }

  writePatientMedicationRx(
    payload: Partial<PatientMedicationForm>,
    correlationId?: CorrelationId,
  ) {
    correlationId = correlationId || uuid();
    this.store.dispatch(
      new WritePatientMedicationRx(payload, { correlationId }),
    );
    return correlationId;
  }

  requestRenewal(payload: PatientRenewalRequest) {
    this.store.dispatch(new RequestRenewal(payload));
  }

  requestChange(payload: PatientChangeRequest) {
    this.store.dispatch(new RequestChange(payload));
  }
}
