import { ErrorHandler, Injectable } from '@angular/core';

import { LoggerService } from '@app/core/logger';
import { isPlainObject } from '@app/utils';

import { getErrorMessage } from './error-utils';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private logger: LoggerService) {}

  handleError(err: any) {
    this.logError(err);
    return err;
  }

  handleErrorSafe(err: any) {
    this.logError(err);

    if (isPlainObject(err)) {
      return err;
    }
    return getErrorMessage(err);
  }

  private logError(err: any) {
    this.logger.error(err);
  }
}
