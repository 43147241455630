import { RxChangeRequestPriorAuthorization } from '@app/features/change-rx/shared/change-rx.type';
import {
  MedForDisplay,
  RxCartMeasurement,
} from '@app/modules/shared-rx/shared-rx.type';

import {
  InteractingMedication,
  MedicationAllergy,
  MedicationRegimen,
  MedicationRegimenForm,
  MedicationRoute,
} from './medications.type';

export enum RxStatus {
  sending = 'Queued',
  failed = 'Failed',
  sent = 'Sent',
  waiting = 'Waiting',
}

export enum RxSource {
  print = 'Printed:',
  fax = 'Fax',
  surescripts = 'eRx',
  handwritten = 'Handwritten:',
}

export type PatientMedicationId = number;

export interface PatientMedication {
  displayDate?: string;
  hasActiveTemplate?: boolean;
  id: number;
  status: PatientMedicationStatus;
  isPrescribable: ReasonAllowed;
  isResumable: ReasonAllowed;
  latestPatientMedicationRegimen?: PatientMedicationRegimen;
  latestRegimen?: MedicationRegimen;
  medicationRouteId: number;
  name: string;
  regimen: string;
  route: MedicationRoute;
  prescriptionHistory?: PatientMedicationPrescriptionHistoryItem[];
  regimenHistory?: PatientMedicationRegimen[];
  hasPrescriptionHistoryLoaded?: boolean;
  hasRegimenHistoryLoaded?: boolean;
  hasDrugInteractionOverride?: boolean;
}

export enum PatientMedicationStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
  DISCONTINUED = 'discontinued',
  PENDING = 'pending',
}

export interface ReasonAllowed {
  allowed?: boolean;
  reason?: string;
}

export interface PatientMedicationRegimen {
  discontinuesAt?: string;
  dispensableDeaCode?: string;
  endedAt?: string;
  id: number;
  startedAt?: string;
  isPrn?: boolean;
  createdBy?: string;
  medicationRegimen?: MedicationRegimen;
}

export interface SureScriptsServiceLevelsItem {
  keyword: string;
  description: string;
}

export interface PrescriptionHistoryPharmacyAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  displayAddressStreet: string;
}

export interface PrescriptionHistoryPharmacy {
  id: number;
  name: string;
  displayName: string;
  isMailOrder: boolean;
  isCustom: boolean;
  isSurescripts: boolean;
  labels: string[];
  workPhoneNumber: string;
  faxPhoneNumber: string;
  ssServiceLevels?: SureScriptsServiceLevelsItem[];
  address?: PrescriptionHistoryPharmacyAddress;
}

export interface PrescriptionHistoryCartSource {
  signedByName: string;
  signedOnBehalfOfId: number;
  signedOnBehalfOfName: string;
  signedOnBehalfOfNpi: string;
  signedOnBehalfOfPrescribingCredential: {
    deaLicenseNumber: string;
    licenseNumber: string;
  };
}

export interface PatientMedicationPrescriptionHistoryItem {
  id: number;
  routeName: string;
  dispenseAsWritten: boolean;
  dispensableDeaCode: string;
  notesToPharmacist: string;
  earliestFillDate: string;
  hasDenialReason: boolean;
  createdAt: string;
  status: string;
  sentAt: string;
  sentAs: string;
  updatedAt: string;
  cartCompleteAt: string;
  displayDate?: string;
  signedById?: number;
  signedOnBehalfOfId: number;
  isCancellable: boolean;
  pharmacy?: PrescriptionHistoryPharmacy;
  pharmacyDescriptor: string;
  providerReason?: string;
  cartSource?: PrescriptionHistoryCartSource;
  medForDisplay?: MedForDisplay;
  rxCartMeasurements?: RxCartMeasurement[];
  rxCancel?: PatientMedicationPrescriptionHistoryItem;
  rxChanges?: PrescriptionChangeItem[];
  originallyPrescribedAt: string;
  resolvedAt: string;
  resolvedStatus: string;
  isCancel: boolean;
  isChange: boolean;
  responseNote?: string;
  responseReason?: string;
  requestType: string;
  originalPrescriptionDescriptor: string;
}

/**
 * Projected Views
 */

export interface PrescriptionHistoryView {
  prescriptionHistories: PatientMedicationPrescriptionHistoryItem[];
}

export interface PatientMedicationWithCartDetails extends PatientMedication {
  cartDetails?: {
    loaded: boolean;
    inCart: boolean;
    isComplete: boolean;
  };
}

export interface MedicationsReconciliationEvent {
  patientId: number;
  reviewedByUserId: number;
  reviewedAtTimestamp: string;
  reviewerDetails?: {
    name: string;
  };
}

export interface MedsRecNotification {
  message?: string;
}

/**
 * Forms
 */

export interface PatientMedicationForm extends MedicationRegimenForm {
  id?: number;
  patientId?: number;
  isConcurrentMedication?: boolean;
  hasDrugInteractionOverride?: boolean;
  medicationInteractions?: InteractingMedication[];
  allergyInteractions?: MedicationAllergy[];
  problemAttachmentIds?: number[];
}

export interface PatientRenewalRequest {
  patientPharmacyId: number;
  createPrescriptionRenewalRequestAttributes: {
    patientMedicationId: number;
    comment?: string;
  };
}

export interface PatientChangeRequest {
  patientMedicationId: number;
  createPrescriptionChangeRequestAttributes: {
    messageRequestCode: string;
    messageRequestSubcodes?: string[];
    medicationOptions?: any[];
  };
}

export interface PrescriptionChangeItem
  extends PatientMedicationPrescriptionHistoryItem {
  rxChangeRequestPriorAuthorization?: RxChangeRequestPriorAuthorization;
}
