import {
  MedicationRegimen,
  MedicationRoute,
} from '@app/modules/medications/shared';
import { RenewalPharmacy } from '@app/modules/pharmacy-picker/shared/pharmacy.type';
import {
  Credential,
  Prescriber,
} from '@app/modules/shared-rx/prescriber-credential.type';
import {
  CartPasswordState,
  MedForDisplay,
  RxCartMeasurement,
  RxPackageSize,
} from '@app/modules/shared-rx/shared-rx.type';
import { DateFormats } from '@app/utils';

export interface OriginalRxPharmacy {
  storeName: string;
  address: string;
  phone: string;
  fax: string;
}

export interface OriginalRxPatient {
  address: string;
  name: string;
  gender: string;
  dateOfBirth: string;
  phone: string;
  fax: string;
  beeper: string;
  homePhone: string;
  workPhone: string;
  otherPhone: string;
}

export interface OriginalRxPrescriber {
  name: string;
  deaLicenseNumber: string;
  licenseNumber: string;
  phone: string;
  address: string;
}

export interface OriginalRxPrescribedMedForDisplay {
  name: string;
  instructions: string;
  quantityDescription: string;
  refills: number;
  notes: string;
  sentAt: string;
  lastFillDate: string;
}

export interface OriginalRxDispensedMedForDisplay {
  name: string;
  instructions: string;
  quantityDescription: string;
  refills: 0;
  notes: null;
  sentAt: string;
  lastFillDate: string;
}
export interface OriginalRx {
  pharmacy: OriginalRxPharmacy;
  patient: OriginalRxPatient;
  prescriber: OriginalRxPrescriber;
  prescribedMedForDisplay: OriginalRxPrescribedMedForDisplay;
  dispensedMedForDisplay: OriginalRxDispensedMedForDisplay;
}

export interface DenialReason {
  code: string;
  description: string;
}

export interface Task {
  id: number;
  assignee: {
    id: number;
    name: string;
  };
}

interface DefaultOption {
  matchedOption: RxPackageSize;
  staticDisplay: null;
  calculatedQuantityDisplay: string;
}

export interface PackageOptions {
  total: number;
  defaultOption: DefaultOption;
  availableOptions: RxPackageSize[];
}

export enum RenewalCartState {
  pending = 'new',
  approved = 'prescribe',
  denied = 'do_not_prescribe',
}

export interface MismatchedPatientDetail {
  dateFormat?: DateFormats;
  requested: string | Date;
  matched: string | Date;
  different: boolean;
}

export interface MismatchedPatientDetails {
  firstName: MismatchedPatientDetail;
  lastName: MismatchedPatientDetail;
  gender: MismatchedPatientDetail;
  dateOfBirth: MismatchedPatientDetail;
  address1: MismatchedPatientDetail;
  address2: MismatchedPatientDetail;
  city: MismatchedPatientDetail;
  state: MismatchedPatientDetail;
  zipCode: MismatchedPatientDetail;
}

export type RenewalClass = 'RefillRequest' | 'PatientRxRequest';

export interface Renewal {
  id: number;
  createdAt: string;
  updatedAt: string;
  cartState: RenewalCartState;
  patientId: number;
  approvedFills: number;
  approvedMedicationPackageSizeId: number;
  dispenseAsWritten: boolean;
  notesToPharmacist: string;
  dispensableMaxTotalFills: number;
  dispensableDeaCode: string;
  earliestFillDate: Date;
  maxEarliestFillDate: string;
  maxApprovedQuantity: number;
  noteToPharmacistMaxCharacters: number;
  notesLegalAddendum: string;
  earliestFillDateInputName: string;
  className: RenewalClass;
  requestedBy: string;
  rxReferenceNumber: string;
  commentFromPatient: string;
  cancellationReason: string;
  createdByLabel: string;
  notesFromPharmacist: string;
  require2Fa: boolean;
  patientOfficeState: string;
  approvedQuantity: number;
  originalRx: OriginalRx;
  pharmacy: RenewalPharmacy;
  medForDisplay: MedForDisplay;
  medicationRoute: MedicationRoute;
  validPrescribers: Prescriber[];
  mustHandwrite: boolean;
  requiresNoteOfMedicalNeed: boolean;
  dispensableControlledSubstance: boolean;
  dispensableRestrictedControlledSubstance: boolean;
  denialReason: DenialReason;
  task: Task;
  packageOptions: PackageOptions;
  medicationRegimen: MedicationRegimen;
  totalComments: number;
  prescriber: Prescriber;
  prescriberId?: number;
  prescribingCredential: Credential;
  medicationRouteId?: number;
  mismatchedPatient?: MismatchedPatientDetails;
}

export interface CheckoutRenewal extends Renewal {
  handwrittenConfirmed: boolean;
  notifyPatient?: boolean;
}

export type RenewalCart = CartPasswordState & {
  id: number;
  notifyPatient: boolean;
  require2Fa: boolean;
  cartCompleteAt?: string;
  createdAt?: string;
  updateAt?: string;
  currentRenewalId?: number;
  rxCartMeasurements?: RxCartMeasurement[];
};

export interface RenewalCartResponse extends RenewalCart {
  cartItems: Renewal[];
}

export interface RenewalCheckoutRequestData {
  password: string;
  refillRequests: CheckoutRenewal[];
  rxCartId: number;
}

export interface RenewalCheckoutModalData {
  renewals: Renewal[];
  acceptsDigitalCommunications: boolean;
  cartId: number;
}
