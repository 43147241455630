import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

import { letterRoutePath } from '@app/features/letter/letter-routing.module';
import { notesRoutePath } from '@app/features/notes/notes-routing.module';
import { renewalsDetailRoutePath } from '@app/features/renewals/renewals-routing.module';
import { timelinePostsRoutePath } from '@app/features/timeline-posts/timeline-posts-routing.module';
import { summariesRoutePath } from '@app/features/workspace/workspace-routing.module';
import {
  orderEditRouteMap,
  orderViewRouteMap,
} from '@app/modules/orders/shared/order-routing-utils';

// Copied from Angular 7.2 internals, default behavior
class DefaultRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(
    route: ActivatedRouteSnapshot,
    detachedTree: DetachedRouteHandle,
  ): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}

@Injectable()
export class ParameterizedRouteReuseStrategy extends DefaultRouteReuseStrategy {
  private refreshableRoutes: string[] = [
    ...Object.values(orderEditRouteMap),
    ...Object.values(orderViewRouteMap),
    timelinePostsRoutePath,
    letterRoutePath,
    summariesRoutePath,
    notesRoutePath,
    renewalsDetailRoutePath,
  ];

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    return this.shouldRefresh(future, curr)
      ? false
      : future.routeConfig === curr.routeConfig;
  }

  private shouldRefresh(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    const oldRouteRefreshable = this.refreshableRoutes.includes(
      this.getPath(future),
    );
    const newRouteRefreshable = this.refreshableRoutes.includes(
      this.getPath(curr),
    );
    const routeIdsMatch = future.params.id === curr.params.id;
    return !oldRouteRefreshable || !newRouteRefreshable || routeIdsMatch
      ? false
      : true;
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    return (route.routeConfig && route.routeConfig.path) || '';
  }
}
