import { FsaAction } from '@app/core/store/shared/fsa-action';
import { ProcedureOrder } from '@app/features/procedure-order/shared/procedure-order.type';

import { LabOrder } from '../lab-order.type';

export enum CloneOrderActionTypes {
  CloneOrder = '[Order] Clone Order',
  CloneOrderError = '[Order] Clone Order Error',
  CloneOrderSuccess = '[Order] Clone Order Success',
}

export type CloneableOrder = LabOrder | ProcedureOrder;
export type CloneError = any;

export class CloneOrder implements FsaAction<CloneableOrder> {
  readonly type = CloneOrderActionTypes.CloneOrder;

  constructor(public payload: CloneableOrder) {}
}

export class CloneOrderSuccess implements FsaAction<CloneableOrder> {
  readonly type = CloneOrderActionTypes.CloneOrderSuccess;
  error = false;

  constructor(public payload: CloneableOrder) {}
}

export class CloneOrderError implements FsaAction<CloneError> {
  readonly type = CloneOrderActionTypes.CloneOrderError;
  error = true;

  constructor(
    public payload: CloneError,
    public meta?: { order: CloneableOrder },
  ) {}
}

export type CloneOrderAction = CloneOrder | CloneOrderSuccess | CloneOrderError;
