<form [formGroup]="form">
  <label>Add Associated Problems</label>
  <omg-auto-complete #problemSelector
                     omgStopEvent
                     placeholder="Select from active problems"
                     formControlName="linkedProblems"
                     bindLabel="label"
                     [items]="allProblems"
                     [hideClearAll]="true"
                     [multiple]="true"
                     (changeAutocomplete)="onChange($event)"
                     (opened)="onOpen()"
                     (closed)="onClose()"
                     (add)="onAdd($event)"
                     (remove)="onRemove($event)"
                     data-cy="problem-selector-input"></omg-auto-complete>
</form>
