import { DatePipe as NgDatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { DateFormats } from '@app/utils/dates/date-formats';

type DateFormatType =
  | '2y'
  | '4y'
  | 'withTime2y'
  | 'withTime2yTz'
  | 'withTime4y'
  | '12Time'
  | '24Time'
  | undefined;

@Pipe({
  name: 'omgDate',
})
export class DatePipe extends NgDatePipe implements PipeTransform {
  transform(
    value: any,
    format?: DateFormatType | string,
    timezone?: string,
    locale?: string,
  ): any {
    let transformedDate = super.transform(
      value,
      this.mapFormat(format),
      timezone,
      locale,
    );
    if (format === 'withTime2yTz') {
      transformedDate += ` ${this.getTimeZone(new Date())}`;
    }

    return transformedDate;
  }

  getTimeZone(date: Date, timezone?: string): string | undefined {
    return new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      timeZoneName: 'short',
    })
      .formatToParts(date)
      .find(part => part.type === 'timeZoneName')?.value;
  }

  private mapFormat(format: DateFormatType | string): DateFormatType | string {
    switch (format) {
      case '2y':
        return DateFormats['2y'];
      case '4y':
        return DateFormats['4y'];
      case 'withoutDay4y':
        return DateFormats['withoutDay4y'];
      case 'withTime2y':
        return DateFormats['withTime2y'];
      case 'withTime2yTz':
        return DateFormats['withTime2yTz'];
      case 'withTime4y':
        return DateFormats['withTime4y'];
      case '12Time':
        return DateFormats['12Time'];
      case '24Time':
        return DateFormats['24Time'];
      default:
        return format;
    }
  }
}
