<div class="omg-dialog-container"
     style="width: 560px"
     data-cy="smart-draft-limitations-modal"
     omgTheme>
  <div omgDialogTitleDirective>
    <h5>Review Draft Before Sending</h5>
    <button omgButton
            variant="none"
            class="om-icon icon-close clickable"
            (click)="close()"></button>
  </div>
  <div omgDialogContentDirective
       om-layout="vertical"
       om-layout-gutter
       class="padding-normal">
    <p>As a reminder, Smart Draft generated messages are not intended to diagnose, prescribe treatment, or serve as a substitute for clinical judgement.</p>
    <div om-layout="vertical">
      <p>Smart Draft does not have access to:</p>
      <ul class="bulleted-list">
        <li>
          The patient's chart (beyond the message thread)
        </li>
        <li>
          Appointment inventory
        </li>
      </ul>
      <p>You must review all messages to verify that clinical advice and information is appropriate for the patient.</p>
    </div>
  </div>

  <div omgDialogActionsDirective
       om-layout="vertical"
       om-layout-gutter
       class="padding-normal">
    <omg-checkbox label="Don't show this warning again"
                    data-cy="dont-show-limitations-again"
                  [(ngModel)]="doNotShowSmartDraftLimitationsAgain"></omg-checkbox>

    <button omgButton
            variant="primary"
            data-cy="submit-dont-show-limitations-again-button"
            (click)="acknowledge()">
      I understand
    </button>
  </div>
</div>
