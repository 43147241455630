import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { FormModel, FormModelOptions } from './form-model';

@Directive({
  exportAs: 'omgForm',
  selector: '[omgForm]',
})
export class FormDirective implements OnInit, OnChanges, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('omgForm') model: FormModel;
  @Input() autosave: boolean;
  @Input() autosaveDelay: number;
  @Input() resetOnSave: boolean;

  @HostBinding('class.om-form') _formClass = true;

  constructor() {}

  ngOnInit(): void {
    if (this.model) {
      this.model.init();
    }
  }

  ngOnDestroy(): void {
    if (this.model) {
      this.model.dispose();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const getCurrentValue = (obj): any => obj?.currentValue;

    // dispose the old form model
    const newModel = getCurrentValue(changes.model);
    if (newModel && this.model) {
      this.model.dispose();
    }

    const options: FormModelOptions = {
      autosave: getCurrentValue(changes.autosave),
      autosaveDelay: getCurrentValue(changes.autosaveDelay),
      resetOnSave: getCurrentValue(changes.resetOnSave),
    };

    // reconfigure the model
    if (this.model) {
      this.model.configure(options);
      this.model.init();
    }
  }
}
