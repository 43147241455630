import { createAction, props } from '@ngrx/store';

import {
  CreateProcedureInteractionIndicationProps,
  DeleteProcedureInteractionIndicationProps,
  ProcedureInteraction,
  RecordProcedureInteractionMeasurementProps,
  UpdateProcedureInteractionProps,
} from '@app/features/procedure-interaction/shared/procedure-interaction.type';
import { TimelineItem } from '@app/features/timeline/shared/timeline.type';

// Create Procedure Interaction
export const createProcedureInteraction = createAction(
  '[Procedure Interaction] Create Procedure Interaction',
  props<{ patientId: number; problemId: number }>(),
);

export const createProcedureInteractionSuccess = createAction(
  '[Procedure Interaction] Create Procedure Interaction Success',
  props<{ procedureInteraction: ProcedureInteraction }>(),
);

export const createProcedureInteractionError = createAction(
  '[Procedure Interaction] Create Procedure Interaction Error',
  props<{ error: any }>(),
);

// Update Procedure Interaction
export const updateProcedureInteraction = createAction(
  '[Procedure Interaction] Update Procedure Interaction',
  props<Partial<UpdateProcedureInteractionProps>>(),
);

export const updateProcedureInteractionSuccess = createAction(
  '[Procedure Interaction] Update Procedure Interaction Success',
  props<{ procedureInteraction: ProcedureInteraction }>(),
);

export const updateProcedureInteractionError = createAction(
  '[Procedure Interaction] Update Procedure Interaction Error',
  props<{ error: any }>(),
);

// Get Procedure Interaction
export const getProcedureInteraction = createAction(
  '[Procedure Interaction] Get Procedure Interaction',
  props<{ id: number }>(),
);

export const getProcedureInteractionSuccess = createAction(
  '[Procedure Interaction] Get Procedure Interaction Success',
  props<{ procedureInteraction: ProcedureInteraction }>(),
);

export const getProcedureInteractionError = createAction(
  '[Procedure Interaction] Get Procedure Interaction Error',
  props<{ error: any }>(),
);

// Create Procedure Interaction Indications

export const createProcedureInteractionIndication = createAction(
  '[Procedure Interaction] Create Procedure Interaction Indication',
  props<Partial<CreateProcedureInteractionIndicationProps>>(),
);

export const createProcedureInteractionIndicationSuccess = createAction(
  '[Procedure Interaction] Create Procedure Interaction Indication Success',
  props<{ procedureInteraction: ProcedureInteraction }>(),
);

export const createProcedureInteractionIndicationError = createAction(
  '[Procedure Interaction] Create Procedure Interaction Indication Error',
  props<{ error: any }>(),
);

// Delete Procedure Interaction Indications

export const deleteProcedureInteractionIndication = createAction(
  '[Procedure Interaction] Delete Procedure Interaction Indication',
  props<Partial<DeleteProcedureInteractionIndicationProps>>(),
);

export const deleteProcedureInteractionIndicationSuccess = createAction(
  '[Procedure Interaction] Delete Procedure Interaction Indication Success',
  props<{ procedureInteraction: ProcedureInteraction }>(),
);

export const deleteProcedureInteractionIndicationError = createAction(
  '[Procedure Interaction] Delete Procedure Interaction Indication Error',
  props<{ error: any }>(),
);

// Record Procedure Interaction Measurement

export const recordProcedureInteractionMeasurement = createAction(
  '[Procedure Interaction] Record Procedure Interaction Measurement',
  props<Partial<RecordProcedureInteractionMeasurementProps>>(),
);

export const recordProcedureInteractionMeasurementSuccess = createAction(
  '[Procedure Interaction] Record Procedure Interaction Measurement Success',
  props<{ procedureInteraction: ProcedureInteraction }>(),
);

export const recordProcedureInteractionMeasurementError = createAction(
  '[Procedure Interaction] Record Procedure Interaction Measurement Error',
  props<{ error: any }>(),
);

// Complete Procedure Interaction
export const completeProcedureInteraction = createAction(
  '[Procedure Interaction] Complete Procedure Interaction',
  props<{
    id: string;
    allowClosedTicket: boolean;
  }>(),
);

export const completeProcedureInteractionSuccess = createAction(
  '[Procedure Interaction] Complete Procedure Interaction Success',
  props<{ procedureInteraction: ProcedureInteraction }>(),
);

export const completeProcedureInteractionError = createAction(
  '[Procedure Interaction] Complete Procedure Interaction Error',
  props<{
    id: string;
    error: any;
  }>(),
);

export const clearCompleteProcedureInteractionError = createAction(
  '[Procedure Interaction] Clear Complete Procedure Interaction Error',
);

// Delete Procedure Interaction
export const deleteProcedureInteraction = createAction(
  '[Procedure Interaction] Delete Procedure Interaction',
  props<{ id: string }>(),
);

export const deleteProcedureInteractionSuccess = createAction(
  '[Procedure Interaction] Delete Procedure Interaction Success',
);

export const deleteProcedureInteractionError = createAction(
  '[Procedure Interaction] Delete Procedure Interaction Error',
  props<{ error: any }>(),
);

// Attach Timeline Document to Procedure Interaction
export const attachTimelineItemToProcedureInteraction = createAction(
  '[Procedure Interaction] Attach Timeline Document To Procedure Interaction',
  props<{ procedureInteractionId: string; timelineItem: TimelineItem }>(),
);

export const attachTimelineItemToProcedureInteractionSuccess = createAction(
  '[Procedure Interaction] Attach Timeline Document To Procedure Interaction Success',
  props<{ procedureInteraction: ProcedureInteraction }>(),
);

export const attachTimelineItemToProcedureInteractionError = createAction(
  '[Procedure Interaction] Attach Timeline Document To Procedure Interaction Error',
  props<{ error: any }>(),
);

export const addResultDocuments = createAction(
  '[Procedure Interaction] Add Result Documents',
  props<{ procedureInteractionId: string; resultDocuments: File[] }>(),
);

export const addResultDocumentsSuccess = createAction(
  '[Procedure Interaction] Add Result Documents Success',
);

export const addResultDocumentsError = createAction(
  '[Procedure Interaction] Add Result Documents Error',
  props<{ error: any }>(),
);

export const deleteResultDocument = createAction(
  '[Procedure Interaction] Delete Result Document',
  props<{ procedureInteractionId: string; documentId: string }>(),
);

export const deleteResultDocumentSuccess = createAction(
  '[Procedure Interaction] Delete Result Document Success',
  props<{ procedureInteraction: ProcedureInteraction }>(),
);

export const deleteResultDocumentError = createAction(
  '[Procedure Interaction] Delete Result Document Error',
  props<{ error: any }>(),
);
