import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { Profile, ProfileSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { Prescriber } from '@app/modules/shared-rx/prescriber-credential.type';
import { addDays, startOfDay } from '@app/utils';

import { Renewal, RenewalCartState } from './renewals.type';

function compareCartState(a: RenewalCartState, b: RenewalCartState) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function sortByPharmacyAndCartState(renewals: Renewal[]) {
  return renewals.sort(
    (a, b) =>
      a.pharmacy.id - b.pharmacy.id ||
      compareCartState(a.cartState, b.cartState),
  );
}

interface RenewalsByPharmacyId {
  [key: string]: Renewal[];
}

function renewalByPharmacyIdReducer(
  acc: RenewalsByPharmacyId,
  renewal: Renewal,
) {
  const pharmacyId = String(renewal.pharmacy.id);
  if (acc[pharmacyId]) {
    acc[pharmacyId].push(renewal);
  } else {
    acc[pharmacyId] = [renewal];
  }
  return acc;
}

export function sortRenewalsByPharmacy(checkoutRenewals: Renewal[]) {
  return Object.values<Renewal[]>(
    checkoutRenewals.reduce(renewalByPharmacyIdReducer, {}),
  );
}

export const isValidPrescriber = (
  profileId: number,
  validPrescribers: Prescriber[],
) =>
  !!(
    profileId &&
    validPrescribers.find(prescriber => {
      return prescriber.id === profileId;
    })
  );
export const calculateEarliestFillDate = (
  siblingFillDate: Date,
  renewalFillDate: Date,
) => {
  if (!siblingFillDate && !renewalFillDate) {
    return startOfDay(new Date());
  } else if (!renewalFillDate && !!siblingFillDate) {
    return startOfDay(addDays(siblingFillDate, 30));
  } else {
    return startOfDay(renewalFillDate);
  }
};

export const cartDenials = (renewals: Renewal[]) =>
  renewals.filter(renewal => renewal.cartState === RenewalCartState.denied);

export const cartApprovals = (renewals: Renewal[]) =>
  renewals.filter(renewal => renewal.cartState === RenewalCartState.approved);

@Injectable()
export class ReadyForCheckoutValidator {
  constructor(
    private profileSelectors: ProfileSelectors,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  isValid(renewal: Renewal): Observable<boolean> {
    return this.profileSelectors.profile.pipe(
      withLatestFrom(this.profileSelectors.hasRole('provider')),
      map(([profile, isProvider]) =>
        this.validate(renewal, profile, isProvider),
      ),
    );
  }

  validate(renewal: Renewal, profile: Profile, isProvider: boolean) {
    const { prescriber, cartState } = renewal;
    if (!prescriber || !prescriber.id) {
      return false;
    }
    if (cartState === RenewalCartState.pending) {
      return false;
    }
    if (
      this.renewalProcessedBySomeoneElse(profile.id, prescriber.id) &&
      this.mustSelfCheckout(renewal, profile, isProvider)
    ) {
      return false;
    }

    return true;
  }

  private mustSelfCheckout(
    renewal: Renewal,
    profile: Profile,
    isProvider: boolean,
  ) {
    const {
      dispensableRestrictedControlledSubstance: restricted,
      pharmacy,
      validPrescribers,
    } = renewal;
    const state = pharmacy && pharmacy.address && pharmacy.address.state;
    const { hasSignatureImage } = profile;
    const removeIsASelfCheckoutState = this.launchDarklyService.variation(
      FeatureFlagNames.orderingRemoveIsSelfCheckoutState,
      false,
    );

    const selfCheckoutRequired =
      restricted ||
      (!removeIsASelfCheckoutState && this.isASelfCheckoutState(state));

    const cannotSignOnBehalfOf = !this.canSignOnBehalfOf(
      validPrescribers,
      hasSignatureImage,
      isProvider,
    );
    return selfCheckoutRequired || cannotSignOnBehalfOf;
  }

  private renewalProcessedBySomeoneElse(
    profileId: number,
    prescriberId: number,
  ) {
    return prescriberId !== profileId;
  }

  private isASelfCheckoutState(state: string) {
    return ['WA', 'NY'].includes(state);
  }

  private canSignOnBehalfOf(
    validPrescribers: Prescriber[] = [],
    hasSignatureImage: boolean,
    isProvider,
  ) {
    return validPrescribers.length > 0 && hasSignatureImage && isProvider;
  }
}
